export const getCreateSegmentTreatmentValueRequest = (formDetails) => {
    let data = formDetails.experimentVariants.reduce((acc, experiment) => {
        const experimentId = experiment.experimentId;
        acc[experimentId] = experiment.variants.reduce((variantAcc, variant) => {
            const variantId = variant.id !== undefined ? variant.id : "-1";
            variantAcc[variantId] = sortObjectByKeys(variant.finalValue);
            return variantAcc;
        }, {});
        return acc;
    }, {})
    return {
        name: formDetails.name,
        value: JSON.stringify(data)
    }
}

export const getUpdateSegmentTreatmentValueRequest = (formDetails) => {
    let data = formDetails.experimentVariants.reduce((acc, experiment) => {
        const experimentId = experiment.experimentId;
        acc[experimentId] = experiment.variants.reduce((variantAcc, variant) => {
            const variantId = variant.id !== undefined ? variant.id : "-1";
            variantAcc[variantId] = sortObjectByKeys(variant.finalValue);
            return variantAcc;
        }, {});
        return acc;
    }, {})
    return {
        id: formDetails.id,
        value: JSON.stringify(data),
        version: formDetails.version
    }
}

const sortObjectByKeys = (obj) => {
    if(obj == null) return [];
    return Object.keys(obj)
        .sort()
        .reduce((sortedObj, key) => {
            sortedObj[key] = obj[key];
            return sortedObj;
        }, {});
};


import React, { useEffect, useState } from "react";

import css from "./mainSection.module.css";

import lodash from "lodash";
import InputText from "../../../components/InputText";
import { ExperimentFormMode } from "../../../services/constants";

const MainSection = (props) => {
  const { mode } = props;

  const { formDetails, handleInputChange, shouldApplyValidation, handleIsValid } = props;

  return (
    <div className={css.mainSectionContainer}>
      <div className={css.descriptionText}>
        Main Section
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Name<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "name", "")}
              keyName="name"
              label="Name"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Name"
              isRequired={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              disabled={mode == ExperimentFormMode.edit}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Description<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "description", "")}
              keyName="description"
              label="Description"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Description"
              isRequired={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              disabled={mode == ExperimentFormMode.edit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default MainSection;
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import css from "./SegmentTreatmentValue.module.css";

import _ from "underscore";
import { CircularProgress } from '@mui/material';

import { makeDeleteAPICall, makeGetAPICAll } from "../../services/api";
import { API, WebsitePageLinks } from "../../services/constants";


import PrimaryButton from "../../components/PrimaryButton";
import AlertDialog from "../../components/AlertDialog";
import AccessChecker from "../../components/AccessChecker";
import Snackbar from "./../../components/Snackbar";
import SegmentTreatmentTable from "../../pageComponents/segmentTreatmentTable";

const SegmentTreatmentValues = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [segmentTreatmentValues, setSegmentTreatmentValues] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [selectedSegmentTreatmentValue, setSelectedSegmentTreatmentValue] = useState({});
  const [alertDialogDetails, setAlertDialogDetails] = useState({ shouldShow: false, title: '', handler: null });


  useEffect(() => {
    getSegmentTreatmentValues();
  }, []);

  const getSegmentTreatmentValues = (filters = { pageNumber: 1 }) => {
    setIsLoading(true);
    makeGetAPICAll(API.getSegmentTreatmentValue, filters).then((response) => {
      setIsLoading(false);
      if (response.success) {
        console.log(response.data);
        setSegmentTreatmentValues(response.data?.treatmentValues);
      } else {
        setResponseMessage(response.data || response.message);
      }
    });
  };

  const hanldeDeleteExperiment = (experimentId) => {
    selectedSegmentTreatmentValue.id = experimentId;
    setSelectedSegmentTreatmentValue({ ...selectedSegmentTreatmentValue });
    setAlertDialogDetails({ shouldShow: true, header: "Are you sure you want to delete this treatment value?", handler: handleDeleteExperimentModalClose })
  }

  const handleDeleteExperimentModalClose = (isSuccess) => {
    setAlertDialogDetails({ shouldShow: false, header: "", handler: null })
    if (isSuccess === true) {
      deleteExperiment();
    }
  }

  const deleteExperiment = () => {
    setIsLoading(true);
    makeDeleteAPICall(API.deleteSegmentTreatmentValue, { id: selectedSegmentTreatmentValue.id }).then((response) => {
      setIsLoading(false);
      if (response.success) {
        getSegmentTreatmentValues();
      } else {
        setResponseMessage(response?.data?.message ?? "Error");
      }
    });
  }

  const handleEditClick = (treatmentValueId) => {
    navigate(WebsitePageLinks.updateSegmentTreatmentValue + "/" + treatmentValueId);
  }

  return (
    <div className={css.experimentsContainer}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className={css.header}>
            <div className={css.headerText}>Segment Treatment Value</div>
            <div className={css.container}>
              <div className={css.buttonContainer}>
                <AccessChecker onClick={() => navigate(WebsitePageLinks.createSegmentTreatmentValue)}>
                  <PrimaryButton text="Create Segment Treatment Value" />
                </AccessChecker>
              </div>
            </div>
          </div>
          <div className={css.tableContainer}>
            <SegmentTreatmentTable
              segmentTreatmentValues={segmentTreatmentValues}
              onEditExperiment={(treatmentValueId) => handleEditClick(treatmentValueId)}
              onDeleteExperiment={(treatmentValueId) => hanldeDeleteExperiment(treatmentValueId)}
            />
          </div>
        </>
      )}
      {alertDialogDetails.shouldShow && (
        <AlertDialog
          showAlert={alertDialogDetails.shouldShow}
          onDialogActionButtonClick={(dialogAction) =>
            alertDialogDetails.handler(dialogAction)
          }
          title={alertDialogDetails.header}
          disagreeText="No"
          agreeText="Yes"
        />
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
  );
};

export default SegmentTreatmentValues;
import React, { useState, useEffect, useRef } from "react";
import css from "./KeysDropdown.module.css";
import GeneralizedSuffixTree from "../../utils/GeneralizedSuffixTree";
import Snackbar from "../Snackbar";

const KeysDropdown = (props) => {
  const { strings, addedKeys } = props;
  const [query, setQuery] = useState(""); // User query
  const [results, setResults] = useState([]); // Autocomplete results
  const [isDropdownVisible, setDropdownVisible] = useState(false); // Control dropdown visibility
  const [responseMessage, setResponseMessage] = useState("");

  const gstRef = useRef(new GeneralizedSuffixTree());
  const containerRef = useRef(null); // Ref for the entire dropdown container

  // Update Generalized Suffix Tree when `strings` prop changes
  useEffect(() => {
    gstRef.current.updateStrings(strings);
  }, [strings]);

  useEffect(() => {
    validateInput(addedKeys);
  }, [props.required, addedKeys]);

  const handleQueryChange = (e) => {
    const input = e.target.value;
    setQuery(input);

    if (input) {
      const matchingIndices = gstRef.current.searchSubstring(input);
      const matchingStrings = [...matchingIndices]
        .map((i) => strings[i])
        .filter((str) => !addedKeys.includes(str)); // Exclude added keys
      setResults(matchingStrings);
      setDropdownVisible(true);
    } else {
      setResults([]);
      setDropdownVisible(false);
    }
  };

  const handleItemSelect = (item) => {
    setQuery(item);
    setDropdownVisible(false);
  };

  const handleAddKey = () => {
    if (query && !addedKeys.includes(query)) {
      setQuery("");
      setResults([]);
      validateInput([...addedKeys, query]);
      props.onAddKey([...addedKeys, query]);
    }
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  const validateInput = (value) => {
    props.required && props.isValid(value?.length > 0, props.keyName);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
    <div
      ref={containerRef}
      className={`select ${props.value || !props.required ? "" : "is-danger"} ${css.dropdownContainer
        }`}
    >
      <input
        type="text"
        placeholder="Start typing to search..."
        value={query}
        onChange={handleQueryChange}
        onFocus={() => setDropdownVisible(results.length > 0)}
        className={css.inputField}
      />

      {isDropdownVisible && results.length > 0 && (
        <ul className={css.dropdownList}>
          {results.map((result, index) => (
            <li
              key={index}
              onClick={() => handleItemSelect(result)}
              className={css.dropdownItem}
            >
              {result}
            </li>
          ))}
        </ul>
      )}

      {/* Add Key Button */}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
     <button onClick={handleAddKey} className={css.addButton}>
     Add Key
   </button>
   </>
  );
};

export default KeysDropdown;
import React from "react";

import css from "./experimentTable.module.css";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import _ from "underscore";
import { DateFormats } from "../../services/constants";
import AccessChecker from "../../components/AccessChecker";
import moment from "moment";

const ExperimentSegmentTable = (props) => {
  const { experimentSegmentKeys } = props;

  const onDeleteExperiment = (discount) => {
    props.onDeleteExperiment(discount);
  };

  return (
    <div className={css.tableContainer}>
      <table className="table is-striped is-fullwidth">
        <thead className={css.tableHeaderContainer}>
          <tr>
            <th className={css.tableHeader}>Segment Key Id</th>
            <th className={css.tableHeader}>Experiment Name</th>
            <th className={css.tableHeader}>Experiment Id</th>
            <th className={css.tableHeader}>Created At</th>
            <th className={css.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(experimentSegmentKeys) &&
            experimentSegmentKeys.map((experimentSegmentKey, index) => {
              return (
                <tr key={index}>
                  <td className={css.tableData}>{experimentSegmentKey.id}</td>
                  <td className={css.tableData}>{experimentSegmentKey.experiment.name}</td>
                  <td className={css.tableData}>{experimentSegmentKey.experiment.id}</td>
                  <td className={css.tableData}>{moment(experimentSegmentKey.createdAt).format(DateFormats.primary)}</td>
                  <td className={css.tableData}>
                    <div className={css.buttonContainer}>
                      {!experimentSegmentKey.isRemoteConfig &&
                        <AccessChecker onClick={() => props.onEditExperiment(experimentSegmentKey.id)}>
                          <div className={css.button}>
                            <EditIcon />
                          </div>
                        </AccessChecker>
                      }
                      <AccessChecker onClick={() => onDeleteExperiment(experimentSegmentKey.id)}>
                        <div className={css.button}>
                          <DeleteIcon />
                        </div>
                      </AccessChecker>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ExperimentSegmentTable;

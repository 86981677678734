import React, { useState } from "react";
import css from "./viewCurrentValueModal.module.css";

const ViewCurrentValueModal = (props) => {
  const { onModalClose, modalData } = props;
  const [isCopied, setIsCopied] = useState(false);

  // Copy function
  const handleCopy = () => {
    if (modalData.value) {
      navigator.clipboard.writeText(JSON.stringify(modalData.value, null, 2));
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000); // Reset the button text after 2 seconds
    }
  };

  return (
    <div className={css.container}>
      {/* Modal Header */}
      <div className={css.header}>
        <span>{modalData.key}</span>
        <div className={css.headerActions}>
          {/* Copy Button */}
          <button onClick={handleCopy} className={css.copyButton} disabled={!modalData.value}>
            {isCopied ? "Copied" : "Copy"}
          </button>
          {/* Close Button */}
          <button onClick={onModalClose} className={css.closeButton}>
            ✖
          </button>
        </div>
      </div>

      {/* Modal Content */}
      <div className={css.content}>
        <pre className={css.jsonView}>
          {JSON.stringify(modalData.value, null, 2)}
        </pre>
      </div>
    </div>
  );
};

export default ViewCurrentValueModal;
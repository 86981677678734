import React, { useEffect, useState } from "react";

import css from "./createTreatmentValueModal.module.css";

import Ajv from "ajv";
import lodash from "lodash";

import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import InputText from "../../components/InputText";
import StatusDropdown from "../../components/StatusDropdown";

import { API, TreatmentValueTypes } from "../../services/constants";
import { makePostAPICAll } from "../../services/api";

import TextArea from "../../components/TextArea";
import { getExperimentTreatmentValue } from "../../model/treatmentValue";
import ExperimentVariableDropdown from "../../components/ExperimentVariableDropdown";
import { isJsonString } from "../../utils/Validator";

const CreateTreatmentValueModal = (props) => {
  const ajv = new Ajv();
  const { onModalClose } = props;
  const valueTypeOptions = [{ text: "Select Value Type", value: "" }, { text: "String", value: TreatmentValueTypes.string }, { text: "JSON", value: TreatmentValueTypes.json }];

  const [formDetails, setFormDetails] = useState({});
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formValidationDetails, setFormValidationDetails] = useState({});

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);

  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const saveFormData = () => {
    if (checkValidation()) {
      makePostAPICAll(API.createTreatmentValue, getExperimentTreatmentValue(formDetails))
        .then(response => {
          if (response.success) {
            onModalClose(true)
          } else {
            setErrorMessage(response?.data?.message);
          }
        })
        .catch(error => {
          setErrorMessage('Could not perform Requested Operation')
        })
    }
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    if (event.target.name === "experimentVariableId") {
      let variableDetails = JSON.parse(event.target[event.target.selectedIndex].getAttribute("variableDetails"));
      formDetails.valueType = variableDetails.valueType;
      formDetails.schema = variableDetails.schema;
    }
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const checkValidation = () => {
    let errorMessage = "";
    if (formDetails.valueType === TreatmentValueTypes.json && !isJsonString(formDetails.value)) {
      setErrorMessage("Invalid JSON for value");
      return false;
    }
    if (formDetails.schema) {
      if (!isJsonString(formDetails.schema)) {
        setErrorMessage("Invalid JSON for schema");
        return false;
      }
      const validate = ajv.compile(JSON.parse(formDetails.schema));
      const valid = validate(JSON.parse(formDetails.value));
      errorMessage = valid ? "" : "Value and Schema mismatch";
    }
    for (let key in formValidationDetails) {
      if (!formValidationDetails[key]) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    setErrorMessage(errorMessage);
    return !Boolean(errorMessage);
  }

  return (
    <div className={css.container}>
      <div className={css.header}>Create Treatment Value</div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Variable<span className={css.required}>*</span></label>
          </div>
          <div>
            <ExperimentVariableDropdown
              onInputChange={handleInputChange}
              value={lodash.get(formDetails, "experimentVariableId", "")}
              keyName="experimentVariableId"
              required={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Value Type<span className={css.required}>*</span></label>
          </div>
          <div>
            <StatusDropdown
              options={valueTypeOptions}
              onInputChange={handleInputChange}
              value={lodash.get(formDetails, "valueType", "")}
              keyName="valueType"
              required={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Name<span className={css.required}>*</span></label>
          </div>
          <div>
            <InputText
              value={lodash.get(formDetails, "name", "")}
              keyName="name"
              label="Name"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Name"
              isRequired={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
            />
          </div>
        </div>

        <div className={css.column}></div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Value<span className={css.required}>*</span></label>
          </div>
          <div>
            <TextArea
              value={lodash.get(formDetails, "value", "")}
              keyName="value"
              label="Value"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Value"
              isRequired={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              disabled={!formDetails.valueType}
              rows={5}
            />
          </div>
        </div>
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Schema</label>
          </div>
          <div>
            <TextArea
              value={lodash.get(formDetails, "schema", "")}
              keyName="schema"
              label="Schema"
              onInputChange={(e) => handleInputChange(e)}
              placeholder="Schema"
              disabled={true}
              rows={5}
            />
          </div>
        </div>
      </div>
      <p className={css.errorMessage}>{errorMessage}</p>
      <div className={css.footer}>
        <div className={css.buttonContainer}>
          <SecondaryButton text={"Discard"} onClick={() => onModalClose(false)} />
        </div>
        <div className={css.buttonContainer}>
          <PrimaryButton text="Save" onClick={handleSaveClick} />
        </div>
      </div>
    </div>
  )
}

export default CreateTreatmentValueModal;
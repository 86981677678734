import { useEffect } from 'react';
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";

import css from './App.module.css';

import { API, CookiesKey, WebsitePageLinks, WebsitePages } from './shared/services/constants';
import { makeGetAPICAll } from './shared/services/api';
import { getCookie } from './shared/utils/Cookies';

import { useUser } from './shared/context/userContext';

import SignIn from './shared/pages/SignIn';
import Dashboard from './shared/pages/Dashboard';
import AddPanelSidebar from './shared/components/AddPanelSidebar';
import CreateExperiment from './shared/pages/CreateExperiment';
import PageNotFound from './shared/pages/PageNotFound';
import Experiments from './shared/pages/Experiments';
import TreatmentValues from './shared/pages/TreatmentValues';
import ExperimentVariables from './shared/pages/ExperimentVariables';
import EditExperiment from './shared/pages/EditExperiment';
import User from './shared/pages/User';
import PrivateRoute from './shared/components/PrivateRoute';
import MoveExperiment from './shared/pages/MoveExperiment';
import CreateRemoteConfig from './shared/pages/CreateRemoteConfig';
import ViewRemoteConfig from './shared/pages/ViewRemoteConfig';
import MoveRemoteConfig from './shared/pages/MoveRemoteConfig';
import ExperimentSegmentKeys from './shared/pages/ExperimentSegmentKeys';
import CreateExperimentSegmentKeys from './shared/pages/CreateExperimentSegmentKeys';
import EditExperimentSegmentKeys from './shared/pages/EditExperimentSegmentKeys';
import CreateSegmentTreatmentValue from './shared/pages/CreateSegmentTreatmentValue';
import SegmentTreatmentValues from './shared/pages/SegmentTreatmentValue';
import UpdateSegmentTreatmentValue from './shared/pages/UpdateSegmentTreatmentValue';
import CreateSegment from './shared/pages/CreateSegment';
import Segments from './shared/pages/Segments';
import UpdateSegment from './shared/pages/UpdateSegment';

function App() {
  let navigate = useNavigate();
  const { setUser } = useUser();

  useEffect(() => {
    let token = getCookie(CookiesKey.adminToken);
    if (!token) {
      navigate(WebsitePageLinks.signIn);
    } else {
      getUser();
    }
  }, []);

  const getUser = () => {
    makeGetAPICAll(API.getUser)
      .then(response => {
        setUser(response.data);
      })
  }

  return (
    <div className={css.wrapper}>
      <Routes>
        <Route exact path={WebsitePages.signIn.path} element={<SignIn />} />
        <Route exact path={WebsitePages.dashboard.path} element={<AddPanelSidebar childComponent={<Dashboard />} />} />
        <Route exact path={WebsitePages.root.path} element={<Navigate to={WebsitePages.experimentVariable.path} />}  />
        <Route exact path={WebsitePages.createExperiment.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<CreateExperiment />} />} />
        <Route exact path={WebsitePages.updateExperiment.path + "/:id"} element={<AddPanelSidebar shouldShowStage={true} childComponent={<EditExperiment />} />} />
        <Route exact path={WebsitePages.viewExperiment.path + "/:id"} element={<AddPanelSidebar shouldShowStage={true} childComponent={<EditExperiment />} />} />
        <Route exact path={WebsitePages.moveExperiment.path + "/:id"} element={<AddPanelSidebar shouldShowStage={true} childComponent={<MoveExperiment />} />} />
        <Route exact path={WebsitePages.createRemoteConfig.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<CreateRemoteConfig />} />} />
        <Route exact path={WebsitePages.viewRemoteConfig.path + "/:id"} element={<AddPanelSidebar shouldShowStage={true} childComponent={<ViewRemoteConfig />} />} />
        <Route exact path={WebsitePages.moveRemoteConfig.path + "/:id"} element={<AddPanelSidebar shouldShowStage={true} childComponent={<MoveRemoteConfig />} />} />
        <Route exact path={WebsitePages.experiment.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<Experiments />} />} />
        <Route exact path={WebsitePages.experimentVariable.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<ExperimentVariables />} />} />
        <Route exact path={WebsitePages.treatmentValue.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<TreatmentValues />} />} />
        <Route exact path={WebsitePages.experimentSegmentKeys.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<ExperimentSegmentKeys />} />} />
        <Route exact path={WebsitePages.createExperimentSegmentKeys.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<CreateExperimentSegmentKeys />} />} />
        <Route exact path={WebsitePages.updateExperimentSegmentKeys.path + "/:id"} element={<AddPanelSidebar shouldShowStage={true} childComponent={<EditExperimentSegmentKeys />} />} />
        <Route exact path={WebsitePages.createSegmentTreatmentValue.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<CreateSegmentTreatmentValue />} />} />
        <Route exact path={WebsitePages.updateSegmentTreatmentValue.path + "/:id"} element={<AddPanelSidebar shouldShowStage={true} childComponent={<UpdateSegmentTreatmentValue />} />} />
        <Route exact path={WebsitePages.segmentTreatmentValue.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<SegmentTreatmentValues />} />} />
        <Route exact path={WebsitePages.updateSegment.path + "/:id"} element={<AddPanelSidebar shouldShowStage={true} childComponent={<UpdateSegment />} />} />
        <Route exact path={WebsitePages.createSegment.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<CreateSegment />} />} />
        <Route exact path={WebsitePages.segment.path} element={<AddPanelSidebar shouldShowStage={true} childComponent={<Segments />} />} />
        <Route exact path={WebsitePages.user.path} element={<PrivateRoute checkAdmin={true} />}>
          <Route exact path={WebsitePages.user.path} element={<AddPanelSidebar childComponent={<User />} />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;

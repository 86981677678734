import React, { useEffect, useState } from "react";

import css from "./appVersionSection.module.css";

import lodash from "lodash";

import PrimaryButton from "../../../components/PrimaryButton";
import { ExperimentFormMode } from "../../../services/constants";
import InputText from "../../../components/InputText";
import SegmentTreatmentValueDropdown from "../../../components/SegmentTreatmentValueDropdown";

const AppVersionSection = (props) => {
  const { mode, formDetails, handleInputChange, shouldApplyValidation, handleIsValid, handleAddExperiment } = props;
  const MAX_APP_VERSION_SIZE = 5;

  return (
    <div className={css.appVersionSectionContainer}>
      <div className={css.descriptionText}>
        App Version Section
      </div>
      {console.log(formDetails)}
      {formDetails.appVersions.map((appVersionDetails, index) => {
        return (
          <div className={css.container} key={index}>
            <div className={css.controlSectionContainer} >
              <div className={css.header}>
                <div className={css.appVersionContainer}>
                  <div className={css.labelContainer}>
                    <label className={css.label}>App Version: </label>
                  </div>
                  <div>
                    <InputText
                      value={lodash.get(appVersionDetails, "appVersion", "")}
                      keyName="appVersion"
                      label="App Version"
                      onInputChange={(e) => handleInputChange(e, index)}
                      placeholder="App Version"
                      isRequired={shouldApplyValidation}
                      isValid={(isValid, property) => handleIsValid(isValid, property)}
                      disabled={mode == ExperimentFormMode.edit && appVersionDetails.isExisting}
                    />
                  </div>
                </div>
                <div className={css.appVersionContainer}>
                  <div className={css.labelContainer}>
                    <label className={css.label}>Treatment Value: </label>
                  </div>
                  <div>
                    <SegmentTreatmentValueDropdown
                      value={lodash.get(appVersionDetails, "treatmentValueId", "")}
                      keyName="treatmentValueId"
                      label="Treatment Value"
                      onInputChange={(e) => handleInputChange(e, index)}
                      placeholder="Treatment Value"
                      isRequired={shouldApplyValidation}
                      isValid={(isValid, property) => handleIsValid(isValid, property)}
                      disabled={mode == ExperimentFormMode.view}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      <div className={css.buttonContainer}>
        {(formDetails.appVersions.length < MAX_APP_VERSION_SIZE) && <PrimaryButton text="Add App Version" onClick={handleAddExperiment} disabled={[ExperimentFormMode.view].includes(mode)} />}
      </div>
    </div>
  )
}

export default AppVersionSection;
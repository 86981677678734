import React from "react";

import css from "./userTable.module.css";

import DeleteIcon from '@mui/icons-material/Delete';

import _ from "underscore";

const KeysTable = (props) => {
  const { keys } = props;

  return (
    <div className={css.tableContainer}>
      <table className="table is-striped is-fullwidth">
        <thead className={css.tableHeaderContainer}>
          <tr>
            <th className={css.tableHeader}>Key</th>
            <th className={css.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(keys) &&
            keys.map((key, index) => {
              return (
                <tr key={index}>
                  <td className={css.tableData}>{key}</td>
                  <td className={css.tableData}>
                    <div className={css.buttonContainer}>
                      <div
                        className={css.button}
                        onClick={() => props.onDeleteKey(key)}
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default KeysTable;

import React, { useEffect, useState } from "react";

import css from "./ExperimentDropdown.module.css";

import { CircularProgress } from '@mui/material';

import Snackbar from "../Snackbar";

import { makeGetAPICAll } from "../../services/api";
import { API, StatusCodeResult } from "../../services/constants";

const ExperimentDropdown = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [experimentData, setExperimentData] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    validateInput(props.value);
    getExperiments();
  }, []);

  useEffect(() => {
    if (props.shouldRefresh) {
      getExperiments();
    }
  }, [props.shouldRefresh]);

  useEffect(() => {
    validateInput(props.value);
  }, [props.required]);

  useEffect(() => {
    if(props.value && experimentData.length > 0)
      handleItemSelect(props.value)
  }, [props.value, experimentData])

  const getExperiments = () => {
    setIsLoading(true);
    makeGetAPICAll(API.getExperiment).then((response) => {
      setIsLoading(false);
      if (response.code === StatusCodeResult.success) {
        setExperimentData(response.data);
      }
    }).catch((error) => setResponseMessage(error))
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (event) => {
    validateInput(event.target.value);
    props.onInputChange(event);
    handleItemSelect(event.target.value);
  };

  const handleItemSelect = (id) => {
    let experiment = experimentData.find(experiment =>  experiment.id == id);
    props.onExperimentChange(experiment.variants ? experiment.variants.map(variant =>  variant.treatmentValueId): [experiment.nonRampUserTreatmentValueId], experiment.variants);
  }

  const validateInput = (value) => {
    props.required && props.isValid(Boolean(value), props.keyName);
  };

  const onCreateExperimentClick = () => {
    if(!props.disabled) {
      props.onCreateExperimentClick();
    }
  }

  return (
    <div
      className={`select ${props.value || !props.required ? "" : "is-danger"} ${css.dropdownContainer
        }`}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (<>
        <select
          className={`${css.dropdown} is-fullwidth`}
          name={props.keyName}
          value={+props.value}
          onChange={handleInputChange}
          disabled={props.disabled}
        >
          <option value="">Select Experiment</option>
          {experimentData &&
            experimentData.map((data) => {
              return (
                <option value={data.id} key={data.id} variableDetails={JSON.stringify(data)}>
                  {data.name}
                </option>
              );
            })}
        </select>
        {(!props.value && props.required) && <p className="help is-danger">Select Experiment</p>}
      </>
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
  );
};

export default ExperimentDropdown;

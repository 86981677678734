import React from "react";

import css from "./segmentTable.module.css";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import _ from "underscore";
import { DateFormats } from "../../services/constants";
import AccessChecker from "../../components/AccessChecker";
import moment from "moment";

const SegmentTable = (props) => {
  const { segments } = props;

  const onDeleteSegment = (treatmentValueId) => {
    props.onDeleteSegment(treatmentValueId);
  };

  return (
    <div className={css.tableContainer}>
      <table className="table is-striped is-fullwidth">
        <thead className={css.tableHeaderContainer}>
          <tr>
            <th className={css.tableHeader}>Id</th>
            <th className={css.tableHeader}>Name</th>
            <th className={css.tableHeader}>Created At</th>
            <th className={css.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(segments) &&
            segments.map((segment, index) => {
              return (
                <tr key={index}>
                  <td className={css.tableData}>{segment.id}</td>
                  <td className={css.tableData}>{segment.name}</td>
                  <td className={css.tableData}>{moment(segment.createdAt).format(DateFormats.primary)}</td>
                  <td className={css.tableData}>
                    <div className={css.buttonContainer}>
                      {!segment.isRemoteConfig &&
                        <AccessChecker onClick={() => props.onEditSegment(segment.id)}>
                          <div className={css.button}>
                            <EditIcon />
                          </div>
                        </AccessChecker>
                      }
                      <AccessChecker onClick={() => onDeleteSegment(segment.id)}>
                        <div className={css.button}>
                          <DeleteIcon />
                        </div>
                      </AccessChecker>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default SegmentTable;

import React, { useEffect, useState } from "react";

import css from "./SegmentKeysDropdown.module.css";

import { CircularProgress } from '@mui/material';

import Snackbar from "../Snackbar";

import { makeGetAPICAll } from "../../services/api";
import { API, StatusCodeResult } from "../../services/constants";
import { isEmpty } from "lodash";

const SegmentKeysDropdown = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [allKeys, setAllKeys] = useState([]);
  const [experimentKeysData, setExperimentKeysData] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    validateInput(props.value);
    getSegmentKeys();
  }, []);

  useEffect(() => {
    if (props.shouldRefresh) {
      getSegmentKeys();
    }
  }, [props.shouldRefresh]);

  useEffect(() => {
    validateInput(props.value);
  }, [props.required]);

  useEffect(()=> {
    if(props.experimentId && allKeys.length > 0) {
      setExperimentKeysData(filterKeys([...allKeys], props.experimentId))
    }
  }, [props.experimentId, allKeys])

  const getSegmentKeys = () => {
    setIsLoading(true);
    makeGetAPICAll(API.getExperimentSegmentKeys).then((response) => {
      setIsLoading(false);
      if (response.code === StatusCodeResult.success) {
        setAllKeys([...response.data?.segmentKeys]);
      }
    }).catch((error) => setResponseMessage(error))
      .finally(() => setIsLoading(false));
  };

  const filterKeys = (keysDetails, experimentId) => {
    if(!experimentId) return [];
    let experimentKeys = keysDetails.find(key=>key.experimentId == experimentId);
    if(isEmpty(experimentKeys)) return [];
    return JSON.parse(experimentKeys.keys)
  }

  const handleInputChange = (event) => {
    validateInput(event.target.value);
    props.onInputChange(event);
  };

  const validateInput = (value) => {
    props.required && props.isValid(Boolean(value), props.keyName);
  };

  const onCreateTreatmentValue = () => {
    if(!props.disabled) {
      props.onCreateTreatmentValue();
    }
  }

  const handleAddKey = () => {

  }

  return (
    <>
    <div
      className={`select ${props.value || !props.required ? "" : "is-danger"} ${css.dropdownContainer
        }`}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (<>
        <select
          className={`${css.dropdown} is-fullwidth`}
          name={props.keyName}
          value={props.value}
          onChange={handleInputChange}
          disabled={props.disabled}
        >
          <option value="">Select Key</option>
          {experimentKeysData?.length > 0 &&
            experimentKeysData.map((data) => {
              return (
                <option value={data} key={data}>
                  {data}
                </option>
              );
            })}
        </select>
        {(!props.value && props.required) && <p className="help is-danger">Select Key</p>}
      </>
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
    </>
  );
};

export default SegmentKeysDropdown;

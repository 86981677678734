import React, { useEffect, useState } from "react";

import css from "./appVersionSection.module.css";

import lodash from "lodash";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import DeleteIcon from "@mui/icons-material/Delete";

import PrimaryButton from "../../../components/PrimaryButton";
import { ExperimentFormMode, ExperimentStatus } from "../../../services/constants";
import VariantSection from "../variantSection";
import ExperimentDropdown from "../../../components/ExperimentDropdown";
import AlertDialog from "../../../components/AlertDialog";

const ExperimentSection = (props) => {
  const { mode, formDetails, treatmentValues, handleAddValueClick, handleDeleteExperimentClick, handleInputChange, shouldApplyValidation, handleIsValid, handleAddTreatment, handleDeleteTreatment, handleExperimentInputChange, handleAddExperiment, handleDeleteValueClick } = props;
  const MAX_APP_VERSION_SIZE = 5;
  const [shouldOpenAppVersionSections, setShouldOpenAppVersionSections] = useState([]);
  const [selectedExperiment, setSelectedExperiment] = useState();
  const [alertDialogDetails, setAlertDialogDetails] = useState({ shouldShow: false, title: '', handler: null });

  useEffect(() => {
    for (let i = 0; i < formDetails.experimentVariants.length; i++) {
      if (shouldOpenAppVersionSections.length <= i) {
        shouldOpenAppVersionSections[i] = formDetails.experimentVariants[i].isExisting != true;
      }
    }
    setShouldOpenAppVersionSections([...shouldOpenAppVersionSections]);
  }, [formDetails.experimentVariants.length])

  const handleExpandClick = (index) => {
    shouldOpenAppVersionSections[index] = !shouldOpenAppVersionSections[index];
    setShouldOpenAppVersionSections([...shouldOpenAppVersionSections]);
  }

  const onDeleteButtonClick = (index) => {
    if (formDetails.experimentVariants[index].experimentId) {
      setSelectedExperiment(index);
      setAlertDialogDetails({ shouldShow: true, header: "Are you sure you want to delete this experiment variant?", handler: handleDeleteExperimentModalClose })
    } else {
      handleDeleteExperimentClick(index);
    }
  }

  const handleDeleteExperimentModalClose = (isSuccess, selectedExperiment) => {
    setAlertDialogDetails({ shouldShow: false, header: "", handler: null })
    if (isSuccess === true) {
      handleDeleteExperimentClick(selectedExperiment);
    }
    setSelectedExperiment(null);
  }


  return (
    <div className={css.appVersionSectionContainer}>
      <div className={css.descriptionText}>
        Experiment - Variant Section
      </div>
      {formDetails.experimentVariants.map((appVersionDetails, index) => {
        return (
          <div className={css.container} key={index}>
            <div className={css.controlSectionContainer} >
              <div className={css.header}>
                <div className={css.appVersionContainer}>
                  <div className={css.labelContainer}>
                    <label className={css.label}>Experiments: </label>
                  </div>
                  <div>
                    <ExperimentDropdown
                      onInputChange={(e) => handleExperimentInputChange(e, index)}
                      value={lodash.get(appVersionDetails, "experimentId", "")}
                      keyName="experimentId"
                      required={shouldApplyValidation}
                      isValid={(isValid, property) => handleIsValid(isValid, property)}
                      onExperimentChange={(treatmentValueIds, variants) => props.handleExperimentChange(treatmentValueIds, variants, index)}
                      disabled={(mode == ExperimentFormMode.edit && appVersionDetails?.isExisting) && ([ExperimentFormMode.view, ExperimentFormMode.edit].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created))}
                    />
                  </div>
                </div>
                <span className={css.expandIcon} >
                  {!([ExperimentFormMode.view].includes(mode) || formDetails.status && formDetails.status !== ExperimentStatus.created) && <DeleteIcon onClick={() => onDeleteButtonClick(index)} />}
                  <span onClick={() => handleExpandClick(index)}>
                    {shouldOpenAppVersionSections[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </span>
                </span>
              </div>
              {
                shouldOpenAppVersionSections[index] && appVersionDetails.experimentId && (
                  <div className={css.rowContainer}>
                    <VariantSection
                      mode={mode}
                      formDetails={formDetails}
                      shouldApplyValidation={shouldApplyValidation}
                      appVersionIndex={index}
                      treatmentValues={treatmentValues}
                      handleInputChange={(event, variantIndex) => handleInputChange(event, index, variantIndex)}
                      handleIsValid={handleIsValid}
                      handleAddTreatment={() => handleAddTreatment(index)}
                      handleDeleteTreatment={(variantIndex) => handleDeleteTreatment(index, variantIndex)}
                      handleAddValueClick={(variantIndex) => handleAddValueClick(index, variantIndex)}
                      handleRemoveValueClick={(variantIndex, key) => handleDeleteValueClick(index, variantIndex, key)}
                    />
                  </div>
                )
              }
            </div>
          </div>
        )
      })}
      <div className={css.buttonContainer}>
        <PrimaryButton text="Add Experiment" onClick={handleAddExperiment} disabled={[ExperimentFormMode.view].includes(mode)} />
      </div>

      {alertDialogDetails.shouldShow && (
        <AlertDialog
          showAlert={alertDialogDetails.shouldShow}
          onDialogActionButtonClick={(dialogAction) =>
            alertDialogDetails.handler(dialogAction, selectedExperiment)
          }
          title={alertDialogDetails.header}
          disagreeText="No"
          agreeText="Yes"
        />
      )}
    </div>
  )
}

export default ExperimentSection;
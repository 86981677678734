import { isObject, isEmpty, isString } from "lodash";

export const addSeparatorInArray = (data, separator = "!@#!") => {
  if (Array.isArray(data)) {
    let answer = "";
    data.forEach((item) => {
      answer = answer + item.text + separator;
    });
    return answer.substring(0, answer.length - separator.length);
  }
  return data;
};

export const queryStringToObject = (queryString) => {
  try {
    if (!queryString && queryString !== false) {
      return {}
    }
    var search = queryString.substring(1);
    return JSON.parse(
      '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"') +
      '"}'
    );
  } catch (error) {
    return "Enter valid query string.";
  }
};

export const objectToSearchParams = (object) => {
  if (isEmpty(object)) return "";
  if (isObject(object)) {
    let queryString = [];
    for (const key in object) {
      queryString.push(`${key}=${object[key]}`);
    }
    return "?" + queryString.join("&");
  }
  return object;
};

export const objectToQueryString = (url, object) => {
  return url + objectToSearchParams(object);
};

export const buildMap = (data, key, value) => {
  let map = {};
  if (isEmpty(data)) return data;
  for (let item of data) {
    map[item[key]] = value ? item[value] : item;
  }
  return map;
};

export const join = (array, modifierFunction) => {
  const modifiedArray = array.map((value) => {
    return modifierFunction(value)
  })

  return modifiedArray.join(",")
}

export const cloneObject = (data) => {
  if (typeof data === 'object' && data !== null) {
    return JSON.parse(JSON.stringify(data))
  }
}

export const removeEmptyEntries = (object) => {
  return Object.fromEntries(Object.entries(object).filter(([_, v]) => !isEmpty(v) || !isNaN(v)))
}

export const removeSpecialCharsAndToTitleCase = (string) => {
  return string
    ?.replace(/(^|\s)\S/g, (t) => t.toUpperCase())
    ?.replace(/[&\\#^+()$~%.'":;_*?<>{}!@[\]=]/g, "")
    ?.replace(/\s+/g, " ");
};

export const toTitleCase = (phrase) => {
  if (!isString(phrase)) return phrase;
  return phrase
    .toLowerCase()
    .trim()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function parseValue(input) {
  try {
    const parsed = JSON.parse(input);
    if (Array.isArray(parsed)) {
      return parsed; // Return as an array
    } else if (typeof parsed === "object" && parsed !== null) {
      return parsed; // Return as an object
    } else if (typeof parsed === "number") {
      return Number.isInteger(parsed) ? parsed : parsed; // Return as a number
    } else if (typeof parsed === "boolean") {
      return parsed; // Return as a boolean
    }
  } catch {
    if (input === "true" || input === "false") {
      return input === "true"; // Convert string to boolean
    } else if (!isNaN(input)) {
      return +input; // Convert numeric string to a number
    }
  }
  return input; // Return the input as is if no other conditions match
}

export const compareSchemAndType = (overrideValue, currentValue) => {
  if (overrideValue == null || currentValue == null) return true; // If overrideValue is null or undefined, consider it valid

  const getType = (value) => {
    if (Array.isArray(value)) return "array";
    if (value !== null && typeof value === "object") return "object";
    return typeof value;
  };

  const prepareArraySchema = (array) => {
    const keyInfo = {};

    array.forEach((item) => {
      for (const key in item) {
        const keyType = getType(item[key]);

        if (!keyInfo[key]) {
          keyInfo[key] = { type: keyType, occurrences: 0 };
        }

        keyInfo[key].occurrences += 1;
      }
    });

    // Determine if a key is mandatory
    for (const key in keyInfo) {
      keyInfo[key].mandatory = keyInfo[key].occurrences === array.length;
    }

    return keyInfo;
  };

  const validateAgainstSchema = (item, schema) => {
    for (const key in schema) {
      const { type, mandatory } = schema[key];
      const actualType = getType(item[key]);

      if (mandatory && !item.hasOwnProperty(key)) {
        console.log(`Missing mandatory key "${key}" in item:`, item);
        return false;
      }

      if (item.hasOwnProperty(key) && actualType !== type) {
        console.log(
          `Type mismatch for key "${key}": expected ${type}, found ${actualType}`
        );
        return false;
      }
    }

    // Check for extra keys in the item that are not in the schema
    for (const key in item) {
      if (!schema.hasOwnProperty(key)) {
        console.log(`Unexpected key "${key}" in item:`, item);
        return false;
      }
    }

    return true;
  };

  const isSchemaMatching = (override, current, path = "") => {
    const overrideType = getType(override);
    const currentType = getType(current);

    if (overrideType !== currentType) {
      console.log(`Type mismatch at ${path || "root"}: ${overrideType} vs ${currentType}`);
      return false;
    }

    if (overrideType === "object") {
      const overrideKeys = Object.keys(override);
      const currentKeys = Object.keys(current);

      // Check if keys match
      return currentKeys.every((key) =>
          current.hasOwnProperty(key) &&
          isSchemaMatching(override[key], current[key])
      );
  }

    if (overrideType === "array") {
      if (current.length === 0 || override.length === 0) return true; // Allow empty arrays

      // Prepare a dynamic schema from the override array
      const schema = prepareArraySchema(current);

      // Validate each item in the current array against the schema
      return override.every((item, index) => {
        const valid = validateAgainstSchema(item, schema);
        if (!valid) {
          console.log(`Item at ${path}[${index}] does not match schema`);
        }
        return valid;
      });
    }

    return true; // Primitive types already match
  };

  return isSchemaMatching(overrideValue, currentValue);
};

export const hasPathCollision = (path1, path2) => {
  // Helper function to normalize and split a path into segments
  const normalizePath = (path) =>
    path
      .replace(/\[(\d+)\]/g, '.$1') // Convert array-like paths (e.g., [0]) to dot notation
      .split('.');

  // Normalize the input paths
  const segments1 = normalizePath(path1);
  const segments2 = normalizePath(path2);

  console.log(segments1);
  console.log(segments2)

  // Compare the segments to detect collisions
  const minLength = Math.min(segments1.length, segments2.length);
  for (let i = 0; i < minLength; i++) {
    if (segments1[i] !== segments2[i]) {
      return false; // Paths diverge
    }
  }

  return true; // Paths collide
}
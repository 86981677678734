import React, { useEffect, useState } from "react";

import css from "./mainSection.module.css";

import lodash, { isEmpty } from "lodash";

import { API, ExperimentFormMode, ExperimentStatus, StatusCodeResult } from "../../../services/constants";
import ExperimentDropdown from "../../../components/ExperimentDropdown";
import { makeGetAPICAll } from "../../../services/api";
import KeysDropdown from "../../../components/KeysDropdown";
import KeysTable from "../keysTable";

const MainSection = (props) => {
  const { mode } = props;
  const [treatmentValues, setTreatmentValues] = useState([]);
  const [segmentTreatmentValues, setSegmentTreatmentValues] = useState([]);
  const [keys, setKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [treatmentValueIds, setTreatmentValueIds] = useState([]);

  const { formDetails, handleInputChange, shouldApplyValidation, handleIsValid } = props;

  useEffect(() => {
    getAllTreatmentValues();
    getAllSegmentTreatmentValues();
  }, []);

  useEffect(() => {
    if (mode == ExperimentFormMode.edit && isEmpty(props.formDetails.keys) == false) {
      setSelectedKeys(props.formDetails.keys);
    }
  }, [formDetails.keys])

  useEffect(() => {
    if (treatmentValueIds.length > 0 && treatmentValues.length > 0) {
      handleTreatmentValueChange();
    }
  }, [treatmentValueIds, treatmentValues])

  const getAllTreatmentValues = () => {
    makeGetAPICAll(API.getTreatmentValue).then((response) => {
      if (response.code === StatusCodeResult.success) {
        setTreatmentValues(response.data);
      }
    })
  }

  const getAllSegmentTreatmentValues = () => {
    makeGetAPICAll(API.getSegmentTreatmentValue).then((response) => {
      if(response.code == StatusCodeResult.success) {
        setSegmentTreatmentValues(response.data.treatmentValues);
      }
    })
  }

  const handleExperimentChange = (treatmentValueIds) => {
    setTreatmentValueIds(treatmentValueIds);
    if(mode == ExperimentFormMode.create) {
      setSelectedKeys([]);
    }
  }

  const handleTreatmentValueChange = () => {
    let uniqueTreatmentValueIds = new Set(), uniqueKeys = [];
    for (let treatmentValueId of treatmentValueIds) {
      if (uniqueTreatmentValueIds.has(treatmentValueId)) continue;
      uniqueTreatmentValueIds.add(treatmentValueId);
      let treatmentValue = treatmentValues.find(treatmentValue => treatmentValue.id == treatmentValueId).value;
      treatmentValue = JSON.parse(treatmentValue);
      uniqueKeys = extractKeys(treatmentValue);
    }
    setKeys(uniqueKeys);
  }

  function extractKeys(obj, existingKeys = new Set()) {
    const keys = new Set(existingKeys);

    function helper(currentObj, parentKey = "", isParentArray = false) {
      if (typeof currentObj === "object" && currentObj !== null) {
        if (Array.isArray(currentObj)) {
          // Add the parent key for the array
          if (parentKey) {
            keys.add(parentKey);
          }
          currentObj.forEach((_, index) => {
            const arrayKey = `${parentKey}[${index}]`;
            keys.add(arrayKey);
          });
        } else if (!isParentArray) {
          // Add object keys only if the parent is not an array
          Object.keys(currentObj).forEach((key) => {
            const newKey = parentKey ? `${parentKey}.${key}` : key;
            keys.add(newKey);
            helper(currentObj[key], newKey, false);
          });
        }
      }
    }

    helper(obj);
    return Array.from(keys);
  }

  const handleAddKey = (keys) => {
    formDetails.keys = keys;
    setSelectedKeys(keys);
  }

  const handleDeleteKey = (removedKey) => {
    for(let segmentTreatmentValue of segmentTreatmentValues) {
      let data = JSON.parse(segmentTreatmentValue.value);
      if(data[formDetails.experimentId]) {
        for(let variantId in data[formDetails.experimentId]) {
          let keys = Object.keys(data[formDetails.experimentId][variantId])
          if(keys.indexOf(removedKey) != -1) {
            props.handleResponseMessage(`Key exists in ${segmentTreatmentValue.name}`);
            return;
          }
        }
      }
    }
    let updatedKeys = selectedKeys.filter(key => key !== removedKey)
    setSelectedKeys(updatedKeys);
    console.log(updatedKeys.length > 0)
    handleIsValid(updatedKeys.length > 0, "keys");
    props.handleRemoveKeys(updatedKeys)
  }

  return (
    <div className={css.mainSectionContainer}>
      <div className={css.descriptionText}>
        Main Section
      </div>
      <div className={`field ${css.row}`}>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Experiment<span className={css.required}>*</span></label>
          </div>
          <div>
            <ExperimentDropdown
              onInputChange={handleInputChange}
              value={lodash.get(formDetails, "experimentId", "")}
              keyName="experimentId"
              required={shouldApplyValidation}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              onExperimentChange={handleExperimentChange}
              disabled={[ExperimentFormMode.view, ExperimentFormMode.edit].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created)}
            />
          </div>
        </div>
        <div className={css.column}>
          <div className={css.labelContainer}>
            <label className={css.label}>Keys<span className={css.required}>*</span></label>
          </div>
          <div>
            <KeysDropdown
              onInputChange={handleInputChange}
              strings={keys}
              value={lodash.get(formDetails, "keys", "")}
              keyName="keys"
              required={shouldApplyValidation}
              addedKeys={selectedKeys}
              isValid={(isValid, property) => handleIsValid(isValid, property)}
              onAddKey={handleAddKey}
              disabled={[ExperimentFormMode.view].includes(mode) || (formDetails.status && formDetails.status !== ExperimentStatus.created)}
            />
          </div>
        </div>

      </div>
      {selectedKeys?.length > 0 &&
        <div className={`${css.tableContainer} ${css.row}`}>
          <KeysTable keys={selectedKeys} onDeleteKey={handleDeleteKey} />
        </div>
      }
    </div>
  )
}

export default MainSection;
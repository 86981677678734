import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import css from "./Experiment.module.css";

import _ from "underscore";
import { CircularProgress } from '@mui/material';

import { makeDeleteAPICall, makeGetAPICAll } from "../../services/api";
import { API, WebsitePageLinks } from "../../services/constants";


import PrimaryButton from "../../components/PrimaryButton";
import AlertDialog from "../../components/AlertDialog";
import AccessChecker from "../../components/AccessChecker";
import Snackbar from "./../../components/Snackbar";
import ExperimentSegmentTable from "../../pageComponents/experimentSegmentTable";

const ExperimentSegmentKeys = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [experimentSegmentKeys, setExperimentSegmentKeys] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [selectedExperimentSegmentKey, setSelectedExperimentSegmentKey] = useState({});
  const [alertDialogDetails, setAlertDialogDetails] = useState({ shouldShow: false, title: '', handler: null });


  useEffect(() => {
    getExperimentSegmentKeys();
  }, []);

  const getExperimentSegmentKeys = (filters = { pageNumber: 1 }) => {
    setIsLoading(true);
    makeGetAPICAll(API.getExperimentSegmentKeys, filters).then((response) => {
      setIsLoading(false);
      if (response.success) {
        console.log(response.data);
        setExperimentSegmentKeys(response.data?.segmentKeys);
      } else {
        setResponseMessage(response.data || response.message);
      }
    });
  };

  const hanldeDeleteExperiment = (experimentId) => {
    selectedExperimentSegmentKey.id = experimentId;
    setSelectedExperimentSegmentKey({ ...selectedExperimentSegmentKey });
    setAlertDialogDetails({ shouldShow: true, header: "Are you sure you want to delete this segment key?", handler: handleDeleteExperimentModalClose })
  }

  const handleDeleteExperimentModalClose = (isSuccess) => {
    setAlertDialogDetails({ shouldShow: false, header: "", handler: null })
    if (isSuccess === true) {
      deleteExperiment();
    }
  }

  const deleteExperiment = () => {
    setIsLoading(true);
    makeDeleteAPICall(API.deleteExperimentSegmentKeys, { id: selectedExperimentSegmentKey.id }).then((response) => {
      setIsLoading(false);
      if (response.success) {
        getExperimentSegmentKeys();
      } else {
        setResponseMessage(response?.data?.message ?? "Error");
      }
    });
  }

  const handleEditClick = (experimentSegmentId) => {
    navigate(WebsitePageLinks.updateExperimentSegmentKeys + "/" + experimentSegmentId);
  }

  return (
    <div className={css.experimentsContainer}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className={css.header}>
            <div className={css.headerText}>Segment Treatment Keys</div>
            <div className={css.container}>
              <div className={css.buttonContainer}>
                <AccessChecker onClick={() => navigate(WebsitePageLinks.createExperimentSegmentKeys)}>
                  <PrimaryButton text="Create Segment Treatment Keys" />
                </AccessChecker>
              </div>
            </div>
          </div>
          <div className={css.tableContainer}>
            <ExperimentSegmentTable
              experimentSegmentKeys={experimentSegmentKeys}
              onEditExperiment={(experimentId) => handleEditClick(experimentId)}
              onDeleteExperiment={(experimentId) => hanldeDeleteExperiment(experimentId)}
            />
          </div>
        </>
      )}
      {alertDialogDetails.shouldShow && (
        <AlertDialog
          showAlert={alertDialogDetails.shouldShow}
          onDialogActionButtonClick={(dialogAction) =>
            alertDialogDetails.handler(dialogAction)
          }
          title={alertDialogDetails.header}
          disagreeText="No"
          agreeText="Yes"
        />
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
  );
};

export default ExperimentSegmentKeys;
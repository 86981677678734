// Suffix Tree Node
class SuffixTreeNode {
    constructor(start = -1, end = -1) {
        this.start = start;
        this.end = end;
        this.children = {};
        this.suffixLink = null;
        this.stringIndices = new Set();
    }

    edgeLength(currentIndex) {
        return (this.end === null ? currentIndex : this.end) - this.start + 1;
    }
}

// Generalized Suffix Tree
class GeneralizedSuffixTree {
    constructor(strings) {
        this.root = new SuffixTreeNode();
        this.strings = [];
        if (strings) {
            this.updateStrings(strings);
        }
    }

    updateStrings(strings) {
        if (!Array.isArray(strings)) {
            this.strings = [];
            this.root = new SuffixTreeNode();
            return;
        }
        this.strings = strings.map((s, i) => s.toLowerCase() + `$${i}`); // Convert to lowercase
        this.root = new SuffixTreeNode();
        this.buildTree();
    }

    buildTree() {
        this.strings.forEach((text, index) => this._buildForString(text, index));
    }

    _buildForString(text, stringIndex) {
        let activeNode = this.root;
        let activeEdge = -1;
        let activeLength = 0;
        let remainingSuffixCount = 0;
        let lastNewNode = null;
        const leafEnd = { value: -1 };

        const extendTree = (pos) => {
            leafEnd.value = pos;
            remainingSuffixCount++;
            lastNewNode = null;

            while (remainingSuffixCount > 0) {
                if (activeLength === 0) activeEdge = pos;

                const edgeChar = text[activeEdge];
                if (!activeNode.children[edgeChar]) {
                    activeNode.children[edgeChar] = new SuffixTreeNode(pos, leafEnd);
                    activeNode.children[edgeChar].stringIndices.add(stringIndex);
                    if (lastNewNode) lastNewNode.suffixLink = activeNode;
                    lastNewNode = null;
                } else {
                    const next = activeNode.children[edgeChar];
                    const edgeLength = next.edgeLength(leafEnd.value);

                    if (activeLength >= edgeLength) {
                        activeEdge += edgeLength;
                        activeLength -= edgeLength;
                        activeNode = next;
                        continue;
                    }

                    if (text[next.start + activeLength] === text[pos]) {
                        if (lastNewNode && activeNode !== this.root) lastNewNode.suffixLink = activeNode;
                        activeLength++;
                        break;
                    }

                    const splitEnd = next.start + activeLength - 1;
                    const splitNode = new SuffixTreeNode(next.start, splitEnd);
                    activeNode.children[edgeChar] = splitNode;

                    splitNode.children[text[pos]] = new SuffixTreeNode(pos, leafEnd);
                    splitNode.children[text[pos]].stringIndices.add(stringIndex);

                    next.start += activeLength;
                    splitNode.children[text[next.start]] = next;

                    if (lastNewNode) lastNewNode.suffixLink = splitNode;
                    lastNewNode = splitNode;
                }

                remainingSuffixCount--;
                activeNode = activeNode.suffixLink || this.root;
            }
        };

        for (let i = 0; i < text.length; i++) extendTree(i);
    }

    searchSubstring(query) {
        const results = new Set();
        const lowerQuery = query.toLowerCase(); // Convert query to lowercase
        this.strings.forEach((str, idx) => {
            if (str.includes(lowerQuery)) results.add(idx);
        });
        return results;
    }
}

export default GeneralizedSuffixTree;
import React, { useEffect, useState } from "react";

import css from "./variantSection.module.css";

import lodash, { isEmpty } from "lodash";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

import TextArea from "../../../components/TextArea";
import { ExperimentFormMode } from "../../../services/constants";
import SegmentKeysDropdown from "../../../components/SegmentKeysDropdown";
import ViewCurrentValueModal from "../../viewCurrentValueModal";
import Modal from "../../../components/Modal";
import JsonTable from "../../../components/JsonTable";

const ExperimentSection = (props) => {
  const { mode, formDetails, treatmentValues, handleAddValueClick, handleInputChange, shouldApplyValidation, handleIsValid, appVersionIndex, handleRemoveValueClick } = props;
  const [shouldOpenTreatmentSections, setShouldOpenTreatmentSections] = useState([]);
  const [shouldShowViewValueModal, setShouldShowViewValueModal] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    for (let i = 0; i < formDetails.experimentVariants[appVersionIndex].variants.length; i++) {
      if (shouldOpenTreatmentSections.length <= i) {
        shouldOpenTreatmentSections[i] = true;
      }
    }
    setShouldOpenTreatmentSections([...shouldOpenTreatmentSections]);
  }, [formDetails.experimentVariants[appVersionIndex].variants.length])

  const handleTreatmentExpandClick = (index) => {
    shouldOpenTreatmentSections[index] = !shouldOpenTreatmentSections[index];
    setShouldOpenTreatmentSections([...shouldOpenTreatmentSections]);
  }

  const onCurrentValueClick = (variant) => {
    let treatmentValue = treatmentValues.find(treatmentValue => treatmentValue.id == variant.treatmentValueId);
    let value = JSON.parse(treatmentValue.value);
    setModalData({ key: variant.key, value: lodash.get(value, variant.key) });
    setShouldShowViewValueModal(true);
  }

  const handleViewClick = (key, value) => {
    setModalData({ key: key, value: value });
    setShouldShowViewValueModal(true);
  }

  const checkIsValidVariantSection = () => {
    let isValid = false;
    if (formDetails?.experimentVariants?.[appVersionIndex]?.variants) {
      for (let variant of formDetails.experimentVariants[appVersionIndex].variants) {
        isValid = isValid || !isEmpty(variant.finalValue);
      }
    }
    return isValid;
  }

  return (
    <div className={css.variantSectionContainer}>
      <div className={css.descriptionText}>
        Variant Section
      </div>

      <div className={css.container}>
        {
          formDetails.experimentVariants[appVersionIndex].variants.map((variant, index) => {
            return (
              <div className={css.controlSectionContainer} key={index}>
                <div className={css.header}>
                  <p className={css.descriptionText}>{lodash.get(variant, "name", "")}</p>
                  <span className={css.expandIcon}>
                    <span onClick={() => handleTreatmentExpandClick(index)}>
                      {shouldOpenTreatmentSections[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </span>
                  </span>
                </div>
                {
                  shouldOpenTreatmentSections[index] && (
                    <div className={css.rowContainer}>
                      <div className={`field ${css.row}`}>
                        <div className={css.column}>
                          <div className={css.labelContainer}>
                            <label className={css.label}>Key</label>
                          </div>
                          <div>
                            <SegmentKeysDropdown
                              onInputChange={(e) => handleInputChange(e, index)}
                              value={lodash.get(variant, "key", "")}
                              keyName="key"
                              required={shouldApplyValidation && !checkIsValidVariantSection()}
                              isValid={(isValid, property) => handleIsValid(isValid, property)}
                              disabled={[ExperimentFormMode.view].includes(mode)}
                              experimentId={formDetails.experimentVariants[appVersionIndex].experimentId}
                            />
                          </div>
                          {!isEmpty(variant.key) && <span className={css.link} onClick={() => onCurrentValueClick(variant)}>View Current Value</span>}
                        </div>
                        <div className={css.column}>
                          <div className={css.labelContainer}>
                            <label className={css.label}>Override Value</label>
                          </div>
                          <div>
                            <TextArea
                              value={lodash.get(variant, `value`, "")}
                              keyName="value"
                              onInputChange={(e) => handleInputChange(e, index)}
                              rows={2}
                              disabled={isEmpty(variant.key)}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={css.addButtonContainer}>
                        <button disabled={isEmpty(variant.value)} onClick={() => handleAddValueClick(index)} className={css.addButton}>
                          Add Value
                        </button>
                      </div>

                      <div className={`field ${css.row}`}>
                        <div className={css.column}>
                          <JsonTable
                            finalValue={lodash.get(variant, "finalValue", {})}
                            onDeleteCllick={(key) => handleRemoveValueClick(index, key)}
                            onViewClick={(key, value) => handleViewClick(key, value)}
                          />
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          })
        }
      </div>
      {shouldShowViewValueModal && (
        <Modal
          isModalOpen={shouldShowViewValueModal}
          handleClose={(event) => setShouldShowViewValueModal(false)}
        >
          <ViewCurrentValueModal
            onModalClose={() => setShouldShowViewValueModal(false)}
            modalData={modalData}
          />
        </Modal>
      )}
    </div>
  )
}

export default ExperimentSection;
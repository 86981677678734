export const getCreateExperimentSegmentRequest = (formDetails) => {
    return {
        experimentId: +formDetails.experimentId,
        keys: formDetails.keys,
    }
}

export const getUpdateExperimentSegmentRequest = (formDetails) => {
    return {
        id: +formDetails.id,
        keys: formDetails.keys,
    }
}
import React, { useEffect, useState } from "react";

import css from "./SegmentTreatmentValueDropdown.module.css";

import { CircularProgress } from '@mui/material';

import Snackbar from "../Snackbar";

import { makeGetAPICAll } from "../../services/api";
import { API, StatusCodeResult } from "../../services/constants";
import { isEmpty } from "lodash";

const SegmentTreatmentValueDropdown = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [treatmentValues, setTreatmentValues] = useState([]);
  const [responseMessage, setResponseMessage] = useState("");

  useEffect(() => {
    validateInput(props.value);
    getTreatmentValues();
  }, []);

  useEffect(() => {
    if (props.shouldRefresh) {
      getTreatmentValues();
    }
  }, [props.shouldRefresh]);

  useEffect(() => {
    validateInput(props.value);
  }, [props.isRequired]);

  const getTreatmentValues = () => {
    setIsLoading(true);
    makeGetAPICAll(API.getSegmentTreatmentValue).then((response) => {
      setIsLoading(false);
      if (response.code === StatusCodeResult.success) {
        setTreatmentValues(response.data.treatmentValues);
      }
    }).catch((error) => setResponseMessage(error))
      .finally(() => setIsLoading(false));
  };

  const handleInputChange = (event) => {
    validateInput(event.target.value);
    props.onInputChange(event);
  };

  const validateInput = (value) => {
    props.isRequired && props.isValid(Boolean(value), props.keyName);
  };

  return (
    <div
      className={`select ${props.value || !props.required ? "" : "is-danger"} ${css.dropdownContainer
        }`}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (<>
        <select
          className={`${css.dropdown} is-fullwidth`}
          name={props.keyName}
          value={+props.value}
          onChange={handleInputChange}
          disabled={props.disabled}
        >
          <option value="">Select Treatment Value</option>
          {treatmentValues &&
            treatmentValues.map((data) => {
              return (
                <option value={data.id} key={data.id}>
                  {data.name}
                </option>
              );
            })}
        </select>
        {(!props.value && props.isRequired) && <p className="help is-danger">Select Treatment Value</p>}
      </>
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
  );
};

export default SegmentTreatmentValueDropdown;

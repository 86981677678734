import React from "react";

import css from "./segmentTreatmentTable.module.css";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import _ from "underscore";
import { DateFormats } from "../../services/constants";
import AccessChecker from "../../components/AccessChecker";
import moment from "moment";

const ExperimentSegmentTable = (props) => {
  const { segmentTreatmentValues } = props;

  const onDeleteExperiment = (treatmentValueId) => {
    props.onDeleteExperiment(treatmentValueId);
  };

  return (
    <div className={css.tableContainer}>
      <table className="table is-striped is-fullwidth">
        <thead className={css.tableHeaderContainer}>
          <tr>
            <th className={css.tableHeader}>Id</th>
            <th className={css.tableHeader}>Name</th>
            <th className={css.tableHeader}>Created At</th>
            <th className={css.tableHeader}>Action</th>
          </tr>
        </thead>
        <tbody>
          {!_.isEmpty(segmentTreatmentValues) &&
            segmentTreatmentValues.map((treatmentValue, index) => {
              return (
                <tr key={index}>
                  <td className={css.tableData}>{treatmentValue.id}</td>
                  <td className={css.tableData}>{treatmentValue.name}</td>
                  <td className={css.tableData}>{moment(treatmentValue.createdAt).format(DateFormats.primary)}</td>
                  <td className={css.tableData}>
                    <div className={css.buttonContainer}>
                      {!treatmentValue.isRemoteConfig &&
                        <AccessChecker onClick={() => props.onEditExperiment(treatmentValue.id)}>
                          <div className={css.button}>
                            <EditIcon />
                          </div>
                        </AccessChecker>
                      }
                      <AccessChecker onClick={() => onDeleteExperiment(treatmentValue.id)}>
                        <div className={css.button}>
                          <DeleteIcon />
                        </div>
                      </AccessChecker>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ExperimentSegmentTable;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import css from "./EditExperiment.module.css";

import MainSection from "../../pageComponents/createExperiment/mainSection";
import AppVersionSection from "../../pageComponents/createExperiment/appVersionSection";

import Snackbar from "../../components/Snackbar";

import { API, DateFormats, ExperimentFormMode, TimeFormats, VariantTypes, WebsitePageLinks } from "../../services/constants";
import { makeGetAPICAll, makePutAPICAll } from "../../services/api";

import { updateExperimentRequest } from "../../model/experiment";
import moment from "moment";
import Header from "../../pageComponents/createExperiment/header";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import { isEmpty } from "lodash";
import { compareVersions, isValidVersion } from "../../utils/Validator";

const EditExperiment = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  const mode = location.pathname.includes(WebsitePageLinks.updateExperiment) ? ExperimentFormMode.edit : ExperimentFormMode.view;
  const headerText = mode === ExperimentFormMode.edit ? "Edit an Experiment" : "View an Experiment", descriptionText = "Your A/B test needs atleast one control and one variant";

  const [experimentDetails, setExperimentDetails] = useState({});
  const [formDetails, setFormDetails] = useState({ appVersions: [{ variants: [{}, {}] }] });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    if (params.id) {
      getExperimentById(params.id);
    }
  }, [params.id])

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);

  const getExperimentById = (experimentId) => {
    makeGetAPICAll(API.getById(experimentId))
      .then(response => {
        if (response.success) {
          setExperimentDetails({ ...response.data, appVersions: getAppVersions(response.data) });
          setFormDetails(getFormDetails({ ...response.data }));
        } else {
          setResponseError(response?.data?.message);
        }
      })
      .catch(error => {
        setResponseError('Could not perform Requested Operation')
      })
  }

  const getFormDetails = (experimentDetails) => {
    return {
      ...experimentDetails,
      startTime: moment(experimentDetails.startDate).format(TimeFormats.inputTime),
      startDate: moment(experimentDetails.startDate).format(DateFormats.inputDate),
      endTime: experimentDetails.endDate ? moment(experimentDetails.endDate).format(TimeFormats.inputTime) : null,
      endDate: experimentDetails.endDate ? moment(experimentDetails.endDate).format(DateFormats.inputDate) : null,
      experimentVariableId: experimentDetails.variableId,
      appVersions: getAppVersions(experimentDetails),
    }
  }

  const getAppVersions = (experimentDetails) => {
    let appVersions = [], appVersionVariantsMapping = {};
    for (let variant of experimentDetails.variants) {
      if (!variant.appVersion) variant.appVersion = "";
      if (!appVersionVariantsMapping[variant.appVersion]) {
        appVersionVariantsMapping[variant.appVersion] = [];
      }
      appVersionVariantsMapping[variant.appVersion].push({ ...variant, allowList: variant.allowedUsers });
    }

    for (let appVersion in appVersionVariantsMapping) {
      appVersions.push({
        appVersion: appVersion ?? "",
        variants: [...appVersionVariantsMapping[appVersion]]
      })
    }
    return [...appVersions];
  }

  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.experiment)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleVariantInputChange = (event, appVersionIndex, variantIndex) => {
    formDetails.appVersions[appVersionIndex].variants[variantIndex][event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleAppVersionInputChange = (event, appVersionIndex) => {
    formDetails.appVersions[appVersionIndex][event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });

  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const handleAddTreatment = (appVersionIndex) => {
    formDetails.appVersions[appVersionIndex].variants.push({ weight: 1, name: "Variant " + String.fromCharCode(65 + formDetails.appVersions[appVersionIndex].variants.length - 1) });
    setFormDetails({ ...formDetails });
  }

  const handleAddAppVersion = () => {
    formDetails.appVersions.push(({ appVersion: "", variants: [{ name: "Control", weight: 1 }, { name: "Variant A", weight: 1 }] }))
    setFormDetails({ ...formDetails });
  }

  const handleDeleteTreatment = (appVersionIndex, variantIndex) => {
    formDetails.appVersions[appVersionIndex].variants.splice(variantIndex, 1);
    setFormDetails({ ...formDetails });
  }

  const saveFormData = () => {
    let changedData = getChangedData();
    if (validateForm(changedData)) {
      setIsSaveClicked(true);
      makePutAPICAll(API.updateExperiment(formDetails.id), updateExperimentRequest(changedData, experimentDetails))
        .then(response => {
          if (response.success) {
            navigate(WebsitePageLinks.experiment)
          } else {
            setResponseError(response?.data?.message);
          }
        })
        .catch(error => {
          setResponseError('Could not perform Requested Operation')
        })
        .finally(() => {
          setIsSaveClicked(false);
        })
    }
  }

  const validateForm = (changedData) => {
    let previousAppVersion = -1;
    for (let appVersionDetails of formDetails.appVersions) {
      if (!isValidVersion(appVersionDetails.appVersion)) {
        setResponseError(`Enter valid app version`);
        return false;
      }
      if (compareVersions(previousAppVersion, appVersionDetails.appVersion) > 0) {
        setResponseError(`App version should be in accending order`);
        return false;
      }
      if (appVersionDetails.variants.length < 2) {
        setResponseError(`Your A/B test needs atleast one control and one variant for app version ${appVersionDetails.appVersion}`);
        return false;
      }
      previousAppVersion = appVersionDetails.appVersion;
    }
    if (isEmpty(changedData)) {
      setResponseError("Nothing is changed!!");
      return false;
    }
    let errorMessage = "";
    for (let key in formValidationDetails) {
      if (formValidationDetails[key] === false) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    setResponseError(errorMessage);
    return !Boolean(errorMessage);
  }

  const getChangedData = () => {
    let changedData = {
      ...(formDetails.name !== experimentDetails.name && { name: formDetails.name }),
      ...(formDetails.description !== experimentDetails.description && { description: formDetails.description }),
      ...(formDetails.startDate !== moment(experimentDetails.startDate).format(DateFormats.inputDate) && { startDate: formDetails.startDate }),
      ...(formDetails.startTime !== moment(experimentDetails.startDate).format(TimeFormats.inputTime) && { startTime: formDetails.startTime }),
      ...(formDetails.endDate !== (experimentDetails.endDate ? moment(experimentDetails.endDate).format(DateFormats.inputDate) : null) && { endDate: formDetails.endDate }),
      ...(formDetails.endTime !== (experimentDetails.endDate ? moment(experimentDetails.endDate).format(TimeFormats.inputTime) : null) && { endTime: formDetails.endTime }),
      ...(formDetails.ramp !== experimentDetails.ramp && { ramp: formDetails.ramp }),
      ...(formDetails.experimentVariableId !== experimentDetails.variableId && { experimentVariableId: formDetails.experimentVariableId }),
      ...(formDetails.audience !== experimentDetails.audience && { audience: formDetails.audience }),
      ...(formDetails.nonRampUserTreatmentValueId !== experimentDetails.nonRampUserTreatmentValueId && { nonRampUserTreatmentValueId: formDetails.nonRampUserTreatmentValueId }),
    }
    let appVersions = getChangedAppVersionsData(formDetails.appVersions, experimentDetails.appVersions);
    if (appVersions.length > 0) {
      changedData.appVersions = appVersions;
    }
    if (changedData.startDate || changedData.startTime) {
      changedData.startDate = formDetails.startDate;
      changedData.startTime = formDetails.startTime;
    }
    if (changedData.endDate || changedData.endTime) {
      changedData.endDate = formDetails.endDate;
      changedData.endTime = formDetails.endTime;
    }
    return changedData;
  }

  const getChangedAppVersionsData = (currentAppVersions, serverAppVersions) => {
    let currentAppVersionIndex = 0, serverAppVersionIndex = 0, changedAppVersionsData = [];
    currentAppVersions = addAppVersionToVariant(currentAppVersions);
    serverAppVersions = addAppVersionToVariant(serverAppVersions);
    while (currentAppVersionIndex < currentAppVersions.length || serverAppVersionIndex < serverAppVersions.length) {
      if (currentAppVersionIndex < currentAppVersions.length && serverAppVersionIndex < serverAppVersions.length) {
        if (currentAppVersions[currentAppVersionIndex].appVersion != serverAppVersions[serverAppVersionIndex].appVersion) {
          changedAppVersionsData.push({ appVersion: currentAppVersions[currentAppVersionIndex].appVersion, variants: getChangedVariantsData(currentAppVersions[currentAppVersionIndex].variants, serverAppVersions[serverAppVersionIndex].variants) });
        } else {
          let changedVariantsData = getChangedVariantsData(currentAppVersions[currentAppVersionIndex].variants, serverAppVersions[serverAppVersionIndex].variants)
          if (changedVariantsData.length > 0) {
            changedAppVersionsData.push({ appVersion: currentAppVersions[currentAppVersionIndex].appVersion, variants: changedVariantsData });
          }
        }
        currentAppVersionIndex++;
        serverAppVersionIndex++;
      } else if (currentAppVersionIndex < currentAppVersions.length) {
        changedAppVersionsData.push({ appVersion: currentAppVersions[currentAppVersionIndex].appVersion, variants: getChangedVariantsData(currentAppVersions[currentAppVersionIndex].variants, []) });
        currentAppVersionIndex++;
      } else if (serverAppVersionIndex < serverAppVersions.length) {
        serverAppVersionIndex++;
      }
    }
    return changedAppVersionsData;
  }

  const getChangedVariantsData = (currentVariants, serverVariants) => {
    let currentVariantIndex = 0, serverVariantsIndex = 0, changedVaritantsData = [];
    while (currentVariantIndex < currentVariants.length || serverVariantsIndex < serverVariants.length) {
      if (currentVariantIndex < currentVariants.length && serverVariantsIndex < serverVariants.length) {
        if (currentVariants[currentVariantIndex].id === serverVariants[serverVariantsIndex].id) {
          let changedVariant = getChangedVariantData(currentVariants[currentVariantIndex], serverVariants[serverVariantsIndex]);
          if (changedVariant !== null) changedVaritantsData.push(changedVariant);
        } else {
          changedVaritantsData.push(currentVariants[currentVariantIndex]);
          changedVaritantsData.push({ id: serverVariants[serverVariantsIndex].id, isActive: 0 })
        }
        currentVariantIndex++; serverVariantsIndex++;
      } else if (currentVariantIndex < currentVariants.length) {
        changedVaritantsData.push({ ...currentVariants[currentVariantIndex], type: currentVariantIndex === 0 ? VariantTypes.control : VariantTypes.treatment });
        currentVariantIndex++;
      } else if (serverVariantsIndex < serverVariants.length) {
        changedVaritantsData.push({ id: serverVariants[serverVariantsIndex].id, isActive: 0 });
        serverVariantsIndex++;
      }
    }
    return changedVaritantsData;
  }

  const getChangedVariantData = (currentVariant, serverVariant) => {
    let changedData = {
      ...(currentVariant.name !== serverVariant.name && { name: currentVariant.name }),
      ...(currentVariant.description !== serverVariant.description && { description: currentVariant.description }),
      ...(+currentVariant.weight !== serverVariant.weight && { weight: currentVariant.weight }),
      ...(currentVariant.treatmentValueId !== serverVariant.treatmentValueId && { treatmentValueId: currentVariant.treatmentValueId }),
      ...(currentVariant.allowList != serverVariant.allowedUsers && { allowedUsers: currentVariant.allowList ? currentVariant.allowList.split(",") : null }),
      ...(currentVariant.appVersion !== serverVariant.appVersion && { appVersion: currentVariant.appVersion?.trim() ?? "" }),
    }
    if (isEmpty(changedData)) {
      return null;
    } else {
      changedData.id = serverVariant.id;
      changedData.type = serverVariant.type;
      changedData.isActive = serverVariant.isActive;
    }
    return changedData;
  }

  const addAppVersionToVariant = (appVersionVariantData) => {
    return appVersionVariantData.map(appVersionData => {
      appVersionData.variants = appVersionData.variants.map(variant => {
        variant.appVersion = appVersionData.appVersion;
        return variant;
      })
      return appVersionData;
    })
  }

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} handleSaveClick={handleSaveClick} handleDiscardClick={handleDiscardClick} />
      <MainSection mode={mode} formDetails={formDetails} shouldApplyValidation={shouldApplyValidation} handleInputChange={handleInputChange} handleIsValid={handleIsValid} />
      <AppVersionSection
        mode={mode}
        formDetails={formDetails}
        shouldApplyValidation={shouldApplyValidation}
        handleInputChange={handleVariantInputChange}
        handleIsValid={handleIsValid}
        handleAddTreatment={handleAddTreatment}
        handleDeleteTreatment={handleDeleteTreatment}
        handleAppVersionInputChange={handleAppVersionInputChange}
        handleAddAppVersion={handleAddAppVersion}
      />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <PrimaryButton text="Save" onClick={handleSaveClick} disabled={isSaveClicked || [ExperimentFormMode.view].includes(mode)} />
        </div>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
    </div>
  )
}

export default EditExperiment;
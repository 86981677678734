export const getCreateSegmentRequest = (formDetails) => {
    return {
        name: formDetails.name,
        description: formDetails.description,
        startDate: Date.now(),
        slug: getSlug(formDetails.name),
        versionMappings: formDetails.appVersions.map((appVersionDetails) => {
            return {
                appVersion: appVersionDetails.appVersion ?? "",
                treatmentValueId: +appVersionDetails.treatmentValueId
            }
        })
    }
}

export const getUpdateSegmentRequest = (formDetails) => {
    return {
        id: formDetails.id,
        version: formDetails.version,
        versionMappings: formDetails.appVersions.map((appVersionDetails) => {
            return {
                id: appVersionDetails.id,
                appVersion: appVersionDetails.appVersion ?? "",
                treatmentValueId: +appVersionDetails.treatmentValueId
            }
        })
    }
}

const getSlug = (name) => {
    return name.toLowerCase().replace(/ /g, '_');
}
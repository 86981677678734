import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import lodash, { isEmpty } from "lodash";

import css from "./UpdateSegmentTreatmentValue.module.css";

import Header from "../../pageComponents/createSegmentTreatmentValue/header";
import MainSection from "../../pageComponents/createSegmentTreatmentValue/mainSection";

import Snackbar from "../../components/Snackbar";

import { API, ExperimentFormMode, StatusCodeResult, WebsitePageLinks } from "../../services/constants";
import { makeGetAPICAll, makePostAPICAll, makePutAPICAll } from "../../services/api";

import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import ExperimentSection from "../../pageComponents/createSegmentTreatmentValue/exerimentSection";
import { getUpdateSegmentTreatmentValueRequest } from "../../model/segmentTreatmentValue";
import { compareSchemAndType, hasPathCollision, parseValue } from "../../utils/Parser";

const UpdateSegmentTreatmentValue = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  const mode = location.pathname.includes(WebsitePageLinks.updateSegmentTreatmentValue) ? ExperimentFormMode.edit : ExperimentFormMode.view;
  const headerText = "Update Segment Treatment Value";
  const descriptionText = "Configure the override values of experiments";

  const [formDetails, setFormDetails] = useState({ experimentVariants: [{ variants: [{ name: "Control", weight: 1 }, { name: "Variant A", weight: 1 }], isExisting: true }] });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [treatmentValues, setTreatmentValues] = useState([]);
  const [segmentTreatmentValue, setSegmentTreatmentValue] = useState({});

  useEffect(() => {
    getAllTreatmentValues();
  }, []);

  useEffect(() => {
    if (params.id) {
      getTreatmentValueById(params.id);
    }
  }, [params.id])

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);

  const getTreatmentValueById = (treatmentValueId) => {
    makeGetAPICAll(API.getSegmentTreatmentValueById(treatmentValueId))
      .then(response => {
        if (response.success) {
          setSegmentTreatmentValue(JSON.parse(JSON.stringify(response.data.treatmentValue)));
          setFormDetails(getFormDetails({ ...response.data.treatmentValue }));
        } else {
          setResponseError(response?.data?.message);
        }
      })
      .catch(error => {
        setResponseError('Could not perform Requested Operation')
      })
  }

  const getAllTreatmentValues = () => {
    makeGetAPICAll(API.getTreatmentValue).then((response) => {
      if (response.code === StatusCodeResult.success) {
        setTreatmentValues(response.data);
      }
    })
  }

  const getFormDetails = (treatmentValue) => {
    let data = JSON.parse(treatmentValue.value);
    let value = [];
    for (let key in data) {
      let item = { experimentId: key, variants: [], isExisting: true };
      for (let variantId in data[key]) {
        item.variants.push({
          variantId: variantId,
          finalValue: data[key][variantId],
          isExisting: true
        })
      }
      value.push(item);
    }
    return {
      id: treatmentValue.id,
      version: treatmentValue.version,
      name: treatmentValue.name,
      experimentVariants: value
    }
  }

  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.segmentTreatmentValue)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const saveFormData = () => {
    if (validateForm()) {
      setIsSaveClicked(true);
      makePutAPICAll(API.updateSegmentTreatmentValue, getUpdateSegmentTreatmentValueRequest(formDetails))
        .then(response => {
          if (response.success) {
            navigate(WebsitePageLinks.segmentTreatmentValue)
          } else {
            setResponseError(response?.data?.message);
          }
        })
        .catch(error => {
          setResponseError('Could not perform Requested Operation')
        })
        .finally(() => {
          setIsSaveClicked(false);
        })
    }
  }

  const validateForm = () => {
    let errorMessage = "";
    let experimentIdMap = {}, hasDuplicateExperiment = false;
    formDetails.experimentVariants.forEach((experimentVariant, index) => {
      if (experimentIdMap[experimentVariant.experimentId]) {
        hasDuplicateExperiment = true;
      }
      let isValidExperimentVariant = false;
      experimentVariant.variants.forEach((item) => {
        isValidExperimentVariant = isValidExperimentVariant || !isEmpty(item.finalValue);
      });
      if (isValidExperimentVariant == false) {
        errorMessage = `Please add value for experimentVariants[${index}]`;
        setResponseError(errorMessage);
        return false;
      }
      experimentIdMap[experimentVariant.experimentId] = 1;
    });
    if (formDetails.experimentVariants.length == 0) {
      errorMessage = "Add atleast 1 experiment";
    } else if (hasDuplicateExperiment) {
      errorMessage = "Please add unique Experiment";
    } else if (!checkIsTreatmentValueChanged()) {
      errorMessage = "Nothing is changed!";
    } else {
      for (let key in formValidationDetails) {
        if (formValidationDetails[key] === false) {
          errorMessage = "Enter valid " + key;
          break;
        }
      }
    }
    setResponseError(errorMessage);
    return !Boolean(errorMessage);
  }

  const checkIsTreatmentValueChanged = () => {
    let payload = getUpdateSegmentTreatmentValueRequest(formDetails);
    return !areJsonStringsEqual(payload.value, segmentTreatmentValue.value);
  }

  const areJsonStringsEqual = (jsonString1, jsonString2) => {
    try {
      const json1 = JSON.parse(jsonString1);
      const json2 = JSON.parse(jsonString2);

      return JSON.stringify(sortObjectByKeys(json1)) === JSON.stringify(sortObjectByKeys(json2));
    } catch (error) {
      console.error("Invalid JSON string", error);
      return false;
    }
  };

  const sortObjectByKeys = (obj) => {
    if (obj == null) return [];
    return Object.keys(obj)
      .sort()
      .reduce((sortedObj, key) => {
        sortedObj[key] = obj[key];
        return sortedObj;
      }, {});
  };


  const handleVariantInputChange = (event, experimentIndex, variantIndex) => {
    formDetails.experimentVariants[experimentIndex].variants[variantIndex][event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleExperimentInputChange = (event, experimentIndex) => {
    formDetails.experimentVariants[experimentIndex][event.target.name] = event.target.value;
    console.log(formDetails);
    setFormDetails({ ...formDetails });

  }

  const handleAddTreatment = (experimentIndex) => {
    formDetails.experimentVariants[experimentIndex].variants.push({ weight: 1, name: "Variant " + String.fromCharCode(65 + formDetails.experimentVariants[experimentIndex].variants.length - 1) });
    setFormDetails({ ...formDetails });
  }

  const handleAddExperiment = () => {
    formDetails.experimentVariants.push(({ variants: [{ name: "Control", weight: 1 }, { name: "Variant A", weight: 1 }] }))
    setFormDetails({ ...formDetails });
  }

  const handleExperimentChange = (treatmentValueIds, variants, index) => {
    if (variants != null && variants.length > 0) {
      if (formDetails.experimentVariants[index]?.variants?.[0]?.variantId) {
        formDetails.experimentVariants[index].variants = formDetails.experimentVariants[index].variants.map(variant => {
          let variantData = variants.find(item => item.id == variant.variantId)
          return { ...variantData, ...variant }
        });
      }
      else {
        let latestVariantAppVersion = variants[variants.length - 1].appVersion;
        let latestVariants = variants.filter(variant => variant.appVersion == latestVariantAppVersion);
        formDetails.experimentVariants[index].variants = latestVariants;
      }
    }
    else {
      formDetails.experimentVariants[index].variants = [{ name: "Default", treatmentValueId: treatmentValueIds[0], ...formDetails.experimentVariants[index].variants[0] }]
    }
    setFormDetails({ ...formDetails })
  }

  const handleAddValueClick = (experimentIndex, variantIndex) => {
    let variant = formDetails.experimentVariants[experimentIndex].variants[variantIndex];
    let treatmentValue = treatmentValues.find(treatmentValue => treatmentValue.id == variant.treatmentValueId);
    let value = JSON.parse(treatmentValue.value);
    if (!isValidKeyPath(variant.key, Object.keys(variant?.finalValue ?? {}))) {
      setResponseError("Duplicate path detected: Adding a key with the same path is not allowed.");
    } else if (compareSchemAndType(parseValue(variant.value), lodash.get(value, variant.key))) {
      let variant = formDetails.experimentVariants[experimentIndex].variants[variantIndex];
      variant.finalValue = { ...variant.finalValue, [variant.key]: parseValue(variant.value) };
      variant.key = "";
      variant.value = null;
      setFormDetails({ ...formDetails });
    } else {
      setResponseError("Schema mismatch of current value and override value");
    }
  }

  const handleDeleteValueClick = (experimentIndex, variantIndex, key) => {
    delete formDetails.experimentVariants[experimentIndex].variants[variantIndex].finalValue[key];
    setFormDetails({ ...formDetails });
  }

  const isValidKeyPath = (newKey, currentKeys) => {
    if (currentKeys.length <= 0) return true;
    for (let currentKey of currentKeys) {
      if (hasPathCollision(newKey, currentKey)) return false;
    }
    return true;
  }

  const handleDeleteExperiment = (index) => {
    formDetails.experimentVariants.splice(index, 1);
    setFormDetails({ ...formDetails })
  }

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} handleSaveClick={handleSaveClick} handleDiscardClick={handleDiscardClick} />
      <MainSection mode={mode} formDetails={formDetails} shouldApplyValidation={shouldApplyValidation} handleInputChange={handleInputChange} handleIsValid={handleIsValid} />
      <ExperimentSection
        mode={mode}
        formDetails={formDetails}
        shouldApplyValidation={shouldApplyValidation}
        treatmentValues={treatmentValues}
        handleInputChange={handleVariantInputChange}
        handleIsValid={handleIsValid}
        handleExperimentInputChange={handleExperimentInputChange}
        handleAddExperiment={handleAddExperiment}
        handleExperimentChange={handleExperimentChange}
        handleAddValueClick={handleAddValueClick}
        handleDeleteExperimentClick={handleDeleteExperiment}
        handleDeleteValueClick={handleDeleteValueClick}
      />
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <PrimaryButton text="Save" onClick={handleSaveClick} disabled={isSaveClicked} />
        </div>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
    </div>
  )
}

export default UpdateSegmentTreatmentValue;
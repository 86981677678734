import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import lodash, { isEmpty, keys } from "lodash";

import css from "./CreateSegmentTreatmentValue.module.css";

import Header from "../../pageComponents/createSegmentTreatmentValue/header";
import MainSection from "../../pageComponents/createSegmentTreatmentValue/mainSection";

import Snackbar from "../../components/Snackbar";

import { API, ExperimentFormMode, StatusCodeResult, WebsitePageLinks } from "../../services/constants";
import { makeGetAPICAll, makePostAPICAll } from "../../services/api";

import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import ExperimentSection from "../../pageComponents/createSegmentTreatmentValue/exerimentSection";
import { getCreateSegmentTreatmentValueRequest } from "../../model/segmentTreatmentValue";
import { compareSchemAndType, hasPathCollision, parseValue } from "../../utils/Parser";

const CreateSegmentTreatmentValue = (props) => {
  let navigate = useNavigate();
  const headerText = "Create Segment Treatment Value";
  const descriptionText = "Configure the override values of experiments";

  const [formDetails, setFormDetails] = useState({ experimentVariants: [{ variants: [{ name: "Control", weight: 1 }, { name: "Variant A", weight: 1 }] }] });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [treatmentValues, setTreatmentValues] = useState([]);

  useEffect(() => {
    getAllTreatmentValues();
  }, []);

  const getAllTreatmentValues = () => {
    makeGetAPICAll(API.getTreatmentValue).then((response) => {
      if (response.code === StatusCodeResult.success) {
        setTreatmentValues(response.data);
      }
    })
  }

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);


  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.segmentTreatmentValue)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const saveFormData = () => {
    if (validateForm()) {
      console.log(formDetails)
      setIsSaveClicked(true);
      makePostAPICAll(API.createSegmentTreatmentValue, getCreateSegmentTreatmentValueRequest(formDetails))
        .then(response => {
          if (response.success) {
            navigate(WebsitePageLinks.segmentTreatmentValue)
          } else {
            setResponseError(response?.data?.message);
          }
        })
        .catch(error => {
          setResponseError('Could not perform Requested Operation')
        })
        .finally(() => {
          setIsSaveClicked(false);
        })
    }
  }

  const validateForm = () => {
    let errorMessage = "";
    let experimentIdMap = {}, hasDuplicateExperiment = false;
    formDetails.experimentVariants.forEach((experimentVariant, index) => {
      if (experimentIdMap[experimentVariant.experimentId]) {
        hasDuplicateExperiment = true;
      }
      let isValidExperimentVariant = false;
      experimentVariant.variants.forEach((item) => {
        isValidExperimentVariant = isValidExperimentVariant || !isEmpty(item.finalValue);
      });
      if (isValidExperimentVariant == false) {
        errorMessage = `Please add value for experimentVariants[${index}]`;
        setResponseError(errorMessage);
        return false;
      }
      experimentIdMap[experimentVariant.experimentId] = 1;
    });
    if (formDetails.experimentVariants.length == 0) {
      errorMessage = "Add atleast 1 experiment";
    }
    else if (hasDuplicateExperiment) {
      errorMessage = "Please add unique Experiment";
    }
    else {
      for (let key in formValidationDetails) {
        if (formValidationDetails[key] === false) {
          errorMessage = "Enter valid " + key;
          break;
        }
      }
    }
    setResponseError(errorMessage);
    return !Boolean(errorMessage);
  }

  const handleVariantInputChange = (event, experimentIndex, variantIndex) => {
    formDetails.experimentVariants[experimentIndex].variants[variantIndex][event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleExperimentInputChange = (event, experimentIndex) => {
    formDetails.experimentVariants[experimentIndex][event.target.name] = event.target.value;
    console.log(formDetails);
    setFormDetails({ ...formDetails });

  }

  const handleAddTreatment = (experimentIndex) => {
    formDetails.experimentVariants[experimentIndex].variants.push({ weight: 1, name: "Variant " + String.fromCharCode(65 + formDetails.experimentVariants[experimentIndex].variants.length - 1) });
    setFormDetails({ ...formDetails });
  }

  const handleAddExperiment = () => {
    formDetails.experimentVariants.push(({ variants: [{ name: "Control", weight: 1 }, { name: "Variant A", weight: 1 }] }))
    setFormDetails({ ...formDetails });
  }

  const handleExperimentChange = (treatmentValueIds, variants, index) => {
    if (variants != null && variants.length > 0) {
      let latestVariantAppVersion = variants[variants.length - 1].appVersion;
      let latestVariants = variants.filter(variant => variant.appVersion == latestVariantAppVersion);
      formDetails.experimentVariants[index].variants = latestVariants;
      setFormDetails({ ...formDetails })
    }
    else {
      formDetails.experimentVariants[index].variants = [{ name: "Default", treatmentValueId: treatmentValueIds[0] }]
    }
  }

  const handleAddValueClick = (experimentIndex, variantIndex) => {
    let variant = formDetails.experimentVariants[experimentIndex].variants[variantIndex];
    let treatmentValue = treatmentValues.find(treatmentValue => treatmentValue.id == variant.treatmentValueId);
    let value = JSON.parse(treatmentValue.value);
    if (!isValidKeyPath(variant.key, Object.keys(variant?.finalValue ?? {}))) {
      setResponseError("Duplicate path detected: Adding a key with the same path is not allowed.");
    } else if (compareSchemAndType(parseValue(variant.value), lodash.get(value, variant.key))) {
      variant.finalValue = { ...variant.finalValue, [variant.key]: parseValue(variant.value) };
      variant.key = "";
      variant.value = null;
      setFormDetails({ ...formDetails });
    } else {
      setResponseError("Schema mismatch of current value and override value");
    }
  }

  const handleDeleteValueClick = (experimentIndex, variantIndex, key) => {
    delete formDetails.experimentVariants[experimentIndex].variants[variantIndex].finalValue[key];
    setFormDetails({ ...formDetails });
  }

  const isValidKeyPath = (newKey, currentKeys) => {
    if (currentKeys.length <= 0) return true;
    for (let currentKey of currentKeys) {
      if (hasPathCollision(newKey, currentKey)) return false;
    }
    return true;
  }

  const handleDeleteExperiment = (index) => {
    formDetails.experimentVariants.splice(index, 1);
    setFormDetails({ ...formDetails })
  }

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} handleSaveClick={handleSaveClick} handleDiscardClick={handleDiscardClick} />
      <MainSection mode={ExperimentFormMode.create} formDetails={formDetails} shouldApplyValidation={shouldApplyValidation} handleInputChange={handleInputChange} handleIsValid={handleIsValid} />
      <ExperimentSection
        mode={ExperimentFormMode.create}
        formDetails={formDetails}
        shouldApplyValidation={shouldApplyValidation}
        treatmentValues={treatmentValues}
        handleInputChange={handleVariantInputChange}
        handleIsValid={handleIsValid}
        handleExperimentInputChange={handleExperimentInputChange}
        handleAddExperiment={handleAddExperiment}
        handleExperimentChange={handleExperimentChange}
        handleAddValueClick={handleAddValueClick}
        handleDeleteExperimentClick={handleDeleteExperiment}
        handleDeleteValueClick={handleDeleteValueClick}
      />
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <PrimaryButton text="Save" onClick={handleSaveClick} disabled={isSaveClicked} />
        </div>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
    </div>
  )
}

export default CreateSegmentTreatmentValue;
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import css from "./EditExperimentSegmentKeys.module.css";

import MainSection from "../../pageComponents/createExperimentSegmentKeys/mainSection";

import Snackbar from "../../components/Snackbar";

import { API, ExperimentFormMode, WebsitePageLinks } from "../../services/constants";
import { makeGetAPICAll, makePutAPICAll } from "../../services/api";

import Header from "../../pageComponents/createExperimentSegmentKeys/header";
import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import { getUpdateExperimentSegmentRequest } from "../../model/experimentSegmentKeys";

const EditExperimentSegmentKeys = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  const mode = location.pathname.includes(WebsitePageLinks.updateExperimentSegmentKeys) ? ExperimentFormMode.edit : ExperimentFormMode.view;
  const headerText = mode === ExperimentFormMode.edit ? "Edit an Segment Treatment Keys" : "View an Segment Treatment Keys", descriptionText = "Specify the keys here that will be overridden by the segment.";

  const [experimentSegmentDetails, setExperimentSegmentDetails] = useState({});
  const [formDetails, setFormDetails] = useState({ appVersions: [{ variants: [{}, {}] }] });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    if (params.id) {
      getExperimentSegmentById(params.id);
    }
  }, [params.id])

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);

  const getExperimentSegmentById = (experimentSegmentId) => {
    makeGetAPICAll(API.getExperimentSegmentKeyById(experimentSegmentId))
      .then(response => {
        if (response.success) {
          console.log(response.data);
          setExperimentSegmentDetails({ ...response.data.segmentKeys });
          setFormDetails(getFormDetails({ ...response.data.segmentKeys }));
        } else {
          setResponseError(response?.data?.message);
        }
      })
      .catch(error => {
        setResponseError('Could not perform Requested Operation')
      })
  }

  const getFormDetails = (experimentSegmentDetails) => {
    return {
      id: experimentSegmentDetails.id,
      experimentId: experimentSegmentDetails.experimentId,
      keys: JSON.parse(experimentSegmentDetails.keys)
    }
  }

  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.experimentSegmentKeys)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleRemoveKeys = (keys) => {
    formDetails.keys = keys;
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const saveFormData = () => {
    if (validateForm()) {
      setIsSaveClicked(true);
      makePutAPICAll(API.updateExperimentSegmentKeys, getUpdateExperimentSegmentRequest(formDetails))
        .then(response => {
          if (response.success) {
            navigate(WebsitePageLinks.experimentSegmentKeys)
          } else {
            setResponseError(response?.data?.message);
          }
        })
        .catch(error => {
          setResponseError('Could not perform Requested Operation')
        })
        .finally(() => {
          setIsSaveClicked(true);
        })
    }
  }

  const validateForm = () => {
    let errorMessage = "";
    let keysfromBackend = JSON.stringify(JSON.parse(experimentSegmentDetails.keys).sort());
    let currentKeys = JSON.stringify([...formDetails.keys].sort());
    if (keysfromBackend == currentKeys) {
      errorMessage = "Nothing is changed!";
    } else {
      for (let key in formValidationDetails) {
        if (formValidationDetails[key] === false) {
          errorMessage = "Enter valid " + key;
          break;
        }
      }
    }
    setResponseError(errorMessage);
    return !Boolean(errorMessage);
  }

  const handleResponseMessage = (message) => {
    setResponseError(message);
  }

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} handleSaveClick={handleSaveClick} handleDiscardClick={handleDiscardClick} />
      <MainSection mode={mode} formDetails={formDetails} shouldApplyValidation={shouldApplyValidation} handleInputChange={handleInputChange} handleIsValid={handleIsValid} handleRemoveKeys={handleRemoveKeys} handleResponseMessage={handleResponseMessage} />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <PrimaryButton text="Save" onClick={handleSaveClick} disabled={isSaveClicked || [ExperimentFormMode.view].includes(mode)} />
        </div>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
    </div>
  )
}

export default EditExperimentSegmentKeys;
import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";

import css from "./SegmentTreatmentValue.module.css";

import _ from "underscore";
import { CircularProgress } from '@mui/material';

import { makeDeleteAPICall, makeGetAPICAll } from "../../services/api";
import { API, WebsitePageLinks } from "../../services/constants";


import PrimaryButton from "../../components/PrimaryButton";
import AlertDialog from "../../components/AlertDialog";
import AccessChecker from "../../components/AccessChecker";
import Snackbar from "../../components/Snackbar";
import SegmentTable from "../../pageComponents/segmentTable";

const Segments = (props) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [segments, setSegments] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [selectedSegment, setSelectedSegment] = useState({});
  const [alertDialogDetails, setAlertDialogDetails] = useState({ shouldShow: false, title: '', handler: null });


  useEffect(() => {
    getSegments();
  }, []);

  const getSegments = (filters = { pageNumber: 1 }) => {
    setIsLoading(true);
    makeGetAPICAll(API.getSegment, filters).then((response) => {
      setIsLoading(false);
      if (response.success) {
        console.log(response.data);
        setSegments(response.data?.segments);
      } else {
        setResponseMessage(response.data || response.message);
      }
    });
  };

  const hanldeDeleteExperiment = (experimentId) => {
    selectedSegment.id = experimentId;
    setSelectedSegment({ ...selectedSegment });
    setAlertDialogDetails({ shouldShow: true, header: "Are you sure you want to delete this segment?", handler: handleDeleteSegment })
  }

  const handleDeleteSegment = (isSuccess) => {
    setAlertDialogDetails({ shouldShow: false, header: "", handler: null })
    if (isSuccess === true) {
      deleteSegment();
    }
  }

  const deleteSegment = () => {
    setIsLoading(true);
    makeDeleteAPICall(API.deleteSegment, { id: selectedSegment.id }).then((response) => {
      setIsLoading(false);
      if (response.success) {
        getSegments();
      } else {
        setResponseMessage(response?.data?.message ?? "Error");
      }
    });
  }

  const handleEditClick = (segmentId) => {
    navigate(WebsitePageLinks.updateSegment + "/" + segmentId);
  }

  return (
    <div className={css.experimentsContainer}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <div className={css.header}>
            <div className={css.headerText}>Segments</div>
            <div className={css.container}>
              <div className={css.buttonContainer}>
                <AccessChecker onClick={() => navigate(WebsitePageLinks.createSegment)}>
                  <PrimaryButton text="Create Segment" />
                </AccessChecker>
              </div>
            </div>
          </div>
          <div className={css.tableContainer}>
            <SegmentTable
              segments={segments}
              onEditSegment={(treatmentValueId) => handleEditClick(treatmentValueId)}
              onDeleteSegment={(treatmentValueId) => hanldeDeleteExperiment(treatmentValueId)}
            />
          </div>
        </>
      )}
      {alertDialogDetails.shouldShow && (
        <AlertDialog
          showAlert={alertDialogDetails.shouldShow}
          onDialogActionButtonClick={(dialogAction) =>
            alertDialogDetails.handler(dialogAction)
          }
          title={alertDialogDetails.header}
          disagreeText="No"
          agreeText="Yes"
        />
      )}
      <Snackbar text={responseMessage} onHide={() => setResponseMessage("")} />
    </div>
  );
};

export default Segments;
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import css from "./CreateSegment.module.css";

import Header from "../../pageComponents/createSegment/header";
import MainSection from "../../pageComponents/createSegment/mainSection";

import Snackbar from "../../components/Snackbar";

import { API, ExperimentFormMode, SegmentReleaseAppVersion, WebsitePageLinks } from "../../services/constants";
import { makePostAPICAll } from "../../services/api";

import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import AppVersionSection from "../../pageComponents/createSegment/appVersionSection";
import { compareVersions, isValidVersion } from "../../utils/Validator";
import { getCreateSegmentRequest } from "../../model/segment";

const CreateSegment = (props) => {
  let navigate = useNavigate();
  const headerText = "Create Segment";
  const descriptionText = "Configure the override values of experiments";

  const [formDetails, setFormDetails] = useState({ appVersions: [{}]});
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);


  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.segment)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const saveFormData = () => {
    if (validateForm()) {
      console.log(formDetails)
      setIsSaveClicked(true);
      makePostAPICAll(API.createSegment, getCreateSegmentRequest(formDetails))
        .then(response => {
          if (response.success) {
            navigate(WebsitePageLinks.segment)
          } else {
            setResponseError(response?.data?.message);
          }
        })
        .catch(error => {
          setResponseError('Could not perform Requested Operation')
        })
        .finally(() => {
          setIsSaveClicked(false);
        })
    }
  }

  const validateForm = () => {
    let previousAppVersion = -1;
    for (let appVersionDetails of formDetails.appVersions) {
      if (!isValidVersion(appVersionDetails.appVersion)) {
        setResponseError(`Enter valid app version`);
        return false;
      }
      if (compareVersions(previousAppVersion, appVersionDetails.appVersion) > 0) {
        setResponseError(`App version should be in accending order`);
        return false;
      }
      if(compareVersions(appVersionDetails.appVersion, SegmentReleaseAppVersion) < 0) {
        setResponseError(`App version should be greater that ${SegmentReleaseAppVersion}`);
        return false;
      }
      previousAppVersion = appVersionDetails.appVersion;
    }

    let errorMessage = "";
    for (let key in formValidationDetails) {
      if (formValidationDetails[key] === false) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    setResponseError(errorMessage);
    return !Boolean(errorMessage);
  }

  const handleVariantInputChange = (event, experimentIndex) => {
    formDetails.appVersions[experimentIndex][event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleAddExperiment = () => {
    formDetails.appVersions.push({})
    setFormDetails({ ...formDetails });
  }

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} handleSaveClick={handleSaveClick} handleDiscardClick={handleDiscardClick} />
      <MainSection mode={ExperimentFormMode.create} formDetails={formDetails} shouldApplyValidation={shouldApplyValidation} handleInputChange={handleInputChange} handleIsValid={handleIsValid} />
      <AppVersionSection
        mode={ExperimentFormMode.create}
        formDetails={formDetails}
        shouldApplyValidation={shouldApplyValidation}
        handleInputChange={handleVariantInputChange}
        handleIsValid={handleIsValid}
        handleAddExperiment={handleAddExperiment}
      />
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <PrimaryButton text="Save" onClick={handleSaveClick} disabled={isSaveClicked} />
        </div>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
    </div>
  )
}

export default CreateSegment;
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import css from "./UpdateSegmentTreatmentValue.module.css";

import Header from "../../pageComponents/createSegment/header";
import MainSection from "../../pageComponents/createSegment/mainSection";

import Snackbar from "../../components/Snackbar";

import { API, ExperimentFormMode, StatusCodeResult, WebsitePageLinks } from "../../services/constants";
import { makeGetAPICAll, makePutAPICAll } from "../../services/api";

import PrimaryButton from "../../components/PrimaryButton";
import SecondaryButton from "../../components/SecondaryButton";
import { getUpdateSegmentTreatmentValueRequest } from "../../model/segmentTreatmentValue";
import AppVersionSection from "../../pageComponents/createSegment/appVersionSection";
import { compareVersions, isValidVersion } from "../../utils/Validator";
import { getUpdateSegmentRequest } from "../../model/segment";

const UpdateSegment = (props) => {
  let navigate = useNavigate();
  let params = useParams();
  let location = useLocation();
  const mode = location.pathname.includes(WebsitePageLinks.updateSegment) ? ExperimentFormMode.edit : ExperimentFormMode.view;
  const headerText = "Update Segment";
  const descriptionText = "Configure the override values of experiments";

  const [formDetails, setFormDetails] = useState({ appVersions: [{}] });
  const [shouldApplyValidation, setShouldApplyValidation] = useState(false);
  const [formValidationDetails, setFormValidationDetails] = useState({});
  const [responseError, setResponseError] = useState("");
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [treatmentValues, setTreatmentValues] = useState([]);
  const [segment, setSegment] = useState({});

  useEffect(() => {
    getAllTreatmentValues();
  }, []);

  useEffect(() => {
    if (params.id) {
      getSegmentById(params.id);
    }
  }, [params.id])

  useEffect(() => {
    if (shouldApplyValidation) {
      saveFormData();
    }
  }, [shouldApplyValidation]);

  const getSegmentById = (id) => {
    makeGetAPICAll(API.getSegment)
      .then(response => {
        console.log(response);
        if (response.success) {
          let segment = response.data.segments.find(segment => segment.id == id);
          setSegment(JSON.parse(JSON.stringify(segment)));
          setFormDetails(getFormDetails({ ...segment }));
        } else {
          setResponseError(response?.data?.message);
        }
      })
      .catch(error => {
        setResponseError('Could not perform Requested Operation')
      })
  }

  const getAllTreatmentValues = () => {
    makeGetAPICAll(API.getTreatmentValue).then((response) => {
      if (response.code === StatusCodeResult.success) {
        setTreatmentValues(response.data);
      }
    })
  }

  const getFormDetails = (appVersionSegment) => {
    console.log({ appVersionSegment })
    return {
      name: appVersionSegment.name,
      description: appVersionSegment.description,
      id: appVersionSegment.id,
      version: appVersionSegment.version,
      appVersions: appVersionSegment.verionMappings.map(item => {
        return {...item, isExisting: true}
      }),
    }
  }

  const handleSaveClick = () => {
    setShouldApplyValidation(true);
    if (shouldApplyValidation) {
      saveFormData();
    }
  }

  const handleDiscardClick = () => {
    navigate(WebsitePageLinks.segment)
  }

  const handleInputChange = (event) => {
    formDetails[event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleIsValid = (isValid, property) => {
    formValidationDetails[property.toString()] = isValid;
    setFormValidationDetails({ ...formValidationDetails });
  };

  const saveFormData = () => {
    if (validateForm()) {
      setIsSaveClicked(true);
      makePutAPICAll(API.updateSegment, getUpdateSegmentRequest(formDetails))
        .then(response => {
          if (response.success) {
            navigate(WebsitePageLinks.segment)
          } else {
            setResponseError(response?.data?.message);
          }
        })
        .catch(error => {
          setResponseError('Could not perform Requested Operation')
        })
        .finally(() => {
          setIsSaveClicked(false);
        })
    }
  }

  const validateForm = () => {
    let previousAppVersion = -1;
    for (let appVersionDetails of formDetails.appVersions) {
      if (!isValidVersion(appVersionDetails.appVersion)) {
        setResponseError(`Enter valid app version`);
        return false;
      }
      if (compareVersions(previousAppVersion, appVersionDetails.appVersion) > 0) {
        setResponseError(`App version should be in accending order`);
        return false;
      }
      previousAppVersion = appVersionDetails.appVersion;
    }
    if(!checkIsSegmentChanged()) {
      setResponseError(`Nothing is changed!`);
      return false;
    }

    let errorMessage = "";
    for (let key in formValidationDetails) {
      if (formValidationDetails[key] === false) {
        errorMessage = "Enter valid " + key;
        break;
      }
    }
    setResponseError(errorMessage);
    return !Boolean(errorMessage);
  }

  const checkIsSegmentChanged = () => {
    let index = 0;
    for(let appVersion of formDetails.appVersions) {
      if(appVersion?.treatmentValueId != segment?.verionMappings?.[index]?.treatmentValueId) return true;
      index++;
    }
    return false;
  }

  const handleVariantInputChange = (event, experimentIndex) => {
    formDetails.appVersions[experimentIndex][event.target.name] = event.target.value;
    setFormDetails({ ...formDetails });
  }

  const handleAddExperiment = () => {
    formDetails.appVersions.push(({ variants: [{ name: "Control", weight: 1 }, { name: "Variant A", weight: 1 }] }))
    setFormDetails({ ...formDetails });
  }

  return (
    <div className={css.pageContainer}>
      <Header headerText={headerText} descriptionText={descriptionText} handleSaveClick={handleSaveClick} handleDiscardClick={handleDiscardClick} />
      <MainSection mode={mode} formDetails={formDetails} shouldApplyValidation={shouldApplyValidation} handleInputChange={handleInputChange} handleIsValid={handleIsValid} />
      <AppVersionSection
        mode={mode}
        formDetails={formDetails}
        shouldApplyValidation={shouldApplyValidation}
        handleInputChange={handleVariantInputChange}
        handleIsValid={handleIsValid}
        handleAddExperiment={handleAddExperiment}
      />
      <Snackbar text={responseError} onHide={() => setResponseError("")} />
      <div className={css.buttonContainer}>
        <div className={css.button}>
          <PrimaryButton text="Save" onClick={handleSaveClick} disabled={isSaveClicked} />
        </div>
        <div className={css.button}>
          <SecondaryButton text="Discard" onClick={handleDiscardClick} />
        </div>
      </div>
    </div>
  )
}

export default UpdateSegment;
import React, { useState } from "react";
import css from "./JsonTable.module.css"; 

const JsonTable = ({ finalValue, onViewClick, onDeleteCllick }) => {
  const handleView = (key, value) => {
    onViewClick(key, value);
  };

  const handleDelete = (key) => {
    onDeleteCllick(key);
  };

  return (
    <div>
      <table className={css.table}>
        <thead>
          <tr>
            <th>Key</th>
            <th>Value</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(finalValue).map(([key, value]) => (
            <tr key={key}>
              <td>{key}</td>
              <td className={css.truncatedValue}>
                {typeof value === "object" ? JSON.stringify(value) : value}
              </td>
              <td>
                <button
                  onClick={() => handleView(key, value)}
                  className={css.actionButton}
                >
                  View
                </button>
                <button
                  onClick={() => handleDelete(key)}
                  className={css.actionButton}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JsonTable;